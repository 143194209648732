import { findKey } from 'lodash/fp';

import {
  REQUEST_A_CALL_CAPTURE,
  REQUEST_A_CALL_COLLAPSE,
  REQUEST_A_CALL_DISMISS,
  REQUEST_A_CALL_EXPAND,
  REQUEST_A_CALL_RESET,
  REQUEST_A_CALL_RULE_BLOCK,
  REQUEST_A_CALL_RULE_TRIGGER,
  REQUEST_A_CALL_SHOW,
} from '../action-types';
import analytics from '../../analytics';
import rules from './utils/rules';

const defaultDelay = 25000;

const capture =
  (delay = defaultDelay) =>
  // @ts-ignore - Automatic, Please fix when editing this file
  (dispatch, getState) => {
    dispatch({
      type: REQUEST_A_CALL_CAPTURE,
    });
    const state = getState();
    const {
      requestACall: { rule },
    } = state;

    // Dismiss toaster window automatically
    setTimeout(
      () =>
        dispatch({
          rule,
          type: REQUEST_A_CALL_DISMISS,
        }),
      delay,
    );
  };

// @ts-ignore - Automatic, Please fix when editing this file
const drawerCapture = () => (dispatch) => {
  dispatch({
    type: REQUEST_A_CALL_CAPTURE,
  });
};

// @ts-ignore - Automatic, Please fix when editing this file
const drawerDismiss = () => (dispatch) => {
  dispatch({
    type: REQUEST_A_CALL_DISMISS,
  });
};

// @ts-ignore - Automatic, Please fix when editing this file
const dismiss = () => (dispatch, getState) => {
  const {
    requestACall: { rule },
  } = getState();
  analytics.publish('action', {
    action: 'dismissed',
    component: 'RequestACall',
    property: rule,
  });
  dispatch({
    type: REQUEST_A_CALL_DISMISS,
  });
};

const checkRules =
  (showOptimisedProductPage = false) =>
  // @ts-ignore - Automatic, Please fix when editing this file
  (dispatch, getState) => {
    const state = getState();

    // Find the first rule that returns true and return the key
    // The `key` will be the return value if the rule checked is true
    const rule = findKey((ruleFunction) => ruleFunction(state, showOptimisedProductPage), rules);

    if (rule) {
      analytics.publish('action', {
        action: 'triggered',
        component: 'RequestACall',
        property: rule,
      });
      dispatch({
        rule,
        type: REQUEST_A_CALL_RULE_TRIGGER,
      });
      analytics.publish('action', {
        action: 'showing',
        component: 'RequestACall',
        property: rule,
      });
      dispatch({
        rule,
        type: REQUEST_A_CALL_SHOW,
      });
    } else {
      // Prevent rule from triggering when user navigates away
      // from target page/conditions.
      dispatch({
        type: REQUEST_A_CALL_RULE_BLOCK,
      });
    }
  };

const expand = () => ({
  type: REQUEST_A_CALL_EXPAND,
});

const collapse = () => ({
  type: REQUEST_A_CALL_COLLAPSE,
});

const reset = () => ({
  type: REQUEST_A_CALL_RESET,
});

// @ts-ignore - Automatic, Please fix when editing this file
const show = (rule) => ({
  reset: true,
  rule,
  type: REQUEST_A_CALL_SHOW,
});

export default {
  capture,
  checkRules,
  collapse,
  dismiss,
  drawerCapture,
  drawerDismiss,
  expand,
  reset,
  show,
};
