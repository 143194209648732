import getValidCoverStartDate from '../get-valid-cover-start-date';
import getValidPaymentStartDate from '../get-valid-payment-start-date';

// @ts-ignore - Automatic, Please fix when editing this file
const getValidBuyFormDates = ({ coverStartDate, paymentStartDate } = {}) => {
  const validCoverStartDate = getValidCoverStartDate({ coverStartDate });
  const validPaymentStartDate = getValidPaymentStartDate({ coverStartDate: validCoverStartDate, paymentStartDate });

  return {
    coverStartDate: validCoverStartDate,
    paymentStartDate: validPaymentStartDate,
  };
};

export default getValidBuyFormDates;
