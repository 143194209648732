import { head, isEmpty } from 'lodash/fp';

import isRequestACallDenylistPage from '../is-request-a-call-denylist-page';

/**
 * Request A Call capture rule = show on non buy flow pages.
 *
 * @param {object} state - Redux state.
 * @param {boolean} showOptimisedProductPage - Indicates if the b-variant (of the showOptimisedProductPage a/b test) is active.
 * @returns {boolean} Flag for whether to show Request A Call.
 */
// @ts-ignore - Automatic, Please fix when editing this file
const getCurrentPage = (state, showOptimisedProductPage = false) =>
  !isEmpty(state.history) && !isRequestACallDenylistPage(head(state.history), showOptimisedProductPage);

export default getCurrentPage;
