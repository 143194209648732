import { bindActionCreators } from 'redux';
import { Clickable, FocusTrap, Image, MemberMenu, NavBarWithoutRouter } from '@ahmdigital/ui';
import { getOr } from 'lodash/fp';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useFeatureValue } from '@growthbook/growthbook-react';
// @ts-ignore - Automatic, Please fix when editing this file
import liveChatActions from '@ahmdigital/livechat/lib/actions';
import React, { useState } from 'react';

import {
  GROWTHBOOK_CHOOSE_SOFT_BUNDLE_EXPERIMENT,
  GROWTHBOOK_CHOOSE_SOFT_BUNDLE_VARIANT,
} from '../../ahm-constants/ui';
import combineMenuItemsWithCmsData from './utils/combine-menu-items-with-cms-data';
import constants from '../../ahm-constants';
import getMenuItems from './menu-items';
import LoginMenu from './components/login-menu';
import logo from './logo.svg';
import selectIsAbcSupportedAndAvailable from '../../selectors/select-is-abc-supported';
import styles from './styles.module.sass';
import useNavigationPerks from './hooks/use-navigation-perks';

type HeaderProps = {
  centerBlock?: React.ReactNode;
  isMinimalMenu: boolean;
};

const Header = ({ centerBlock, isMinimalMenu }: HeaderProps) => {
  const [isActive, setIsActive] = useState(false);
  const isAbcSupportedAndAvailable: boolean = useSelector(selectIsAbcSupportedAndAvailable);
  const liveChatStatus: number = useSelector((state: RootStateOrAny) => state.livechat.status);
  const location: { pathname?: string } = useSelector((state: RootStateOrAny) => state.router.location);
  const dispatch = useDispatch();
  const actionsLiveChat = bindActionCreators(liveChatActions, dispatch);

  const chooseSoftBundleValue = useFeatureValue(
    GROWTHBOOK_CHOOSE_SOFT_BUNDLE_EXPERIMENT,
    GROWTHBOOK_CHOOSE_SOFT_BUNDLE_VARIANT.ORIGINAL,
  );
  const showSoftBundle = chooseSoftBundleValue === GROWTHBOOK_CHOOSE_SOFT_BUNDLE_VARIANT.NEW;

  const menuItems = getMenuItems(actionsLiveChat, isAbcSupportedAndAvailable, liveChatStatus, location, showSoftBundle);
  const data = getOr([], 'items', useNavigationPerks());

  const imageProps = {
    alt: 'Go to ahm home',
    className: styles.logoImage,
    height: 69,
    layout: 'raw',
    priority: true,
    src: logo,
    useNextImage: true,
    width: 164,
  };

  return (
    <FocusTrap isActive={isActive}>
      <MemberMenu menu={<LoginMenu />} centerBlock={centerBlock} />
      <NavBarWithoutRouter
        onStateChange={({ isOpen }: { isOpen: boolean }) => setIsActive(isOpen)}
        menuItems={combineMenuItemsWithCmsData(menuItems, data)}
        independentSubMenus
        isAccordionMenu
        isMinimal={isMinimalMenu}
        logo={
          <Clickable to={constants.URLS.SALES.HOME} title="Go to ahm home">
            <Image {...imageProps} />
          </Clickable>
        }
      />
    </FocusTrap>
  );
};

export default Header;
