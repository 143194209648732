import { get, isEmpty, set } from 'lodash/fp';
// @ts-ignore - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';
import { REHYDRATE } from 'redux-persist';

import {
  BUY_SUBMIT,
  BUY_UPDATE_DATA,
  PAYMENT_SUBMIT,
  PAYMENT_SUBMIT_FAIL,
  PAYMENT_SUBMIT_SUCCESS,
  PAYMENT_UPDATE_DATA,
} from '../../actions/action-types';
import formLogging from '../../utils/form-logging';

const initialState = {
  buy: {},
  payment: {},
};
/**
 * Checkout represents the customer’s progression through the checkout process. It contains the data submitted.
 *
 * @param {object} state - The checkout branch of state in its current form.
 * @param {object} action - Redux action.
 * @returns {object} A new object that represents the checkout branch of state.
 */

export default function checkoutReducer(state = initialState, action = {}) {
  const reducers = {
    [BUY_SUBMIT]: () => {
      formLogging({
        // @ts-ignore - Automatic, Please fix when editing this file
        data: action.data,
        identifier: 'checkout BUY_SUBMIT',
        isOptionalMedicare: true,
        level: 'info',
      });
      // @ts-ignore - Automatic, Please fix when editing this file
      return set('buy', { ...state.buy, ...action.data }, state);
    },
    // @ts-ignore - Automatic, Please fix when editing this file
    [BUY_UPDATE_DATA]: () => set('buy', { ...state.buy, ...action.data }, state),
    // card case needs merge since PAYMENT_UPDATE_DATA happens first.
    [PAYMENT_SUBMIT]: () =>
      // @ts-ignore - Automatic, Please fix when editing this file
      Immutable.asMutable(Immutable.set(state, 'payment', Immutable.merge(state.payment, action.data))),
    // Clear the card payment details so that the card form can be re-submitted
    [PAYMENT_SUBMIT_FAIL]: () =>
      Immutable.asMutable(
        Immutable.set(
          state,
          'payment',
          Immutable.merge(state.payment, {
            expiry: null,
            name: null,
            token: null,
            type: null,
          }),
        ),
      ),
    [PAYMENT_SUBMIT_SUCCESS]: () => initialState, // Clear the checkout state once the user has joined
    // @ts-ignore - Automatic, Please fix when editing this file
    [PAYMENT_UPDATE_DATA]: () => Immutable.asMutable(Immutable.set(state, 'payment', action.data)),
    [REHYDRATE]: () => {
      if (
        // @ts-ignore - Automatic, Please fix when editing this file
        action.key === 'checkout' &&
        get('payload', action) &&
        // @ts-ignore - Automatic, Please fix when editing this file
        (!isEmpty(action.payload.buy) || !isEmpty(action.payload.payment))
      ) {
        formLogging({
          // @ts-ignore - Automatic, Please fix when editing this file
          data: action.payload.buy,
          identifier: 'checkout REHYDRATE',
          level: 'info',
        });
        // @ts-ignore - Automatic, Please fix when editing this file
        return Immutable.asMutable(Immutable.merge(state, action.payload));
      }

      return state;
    },
  };
  // @ts-ignore - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
