import { get, map } from 'lodash/fp';
import formatCurrency from '@ahmdigital/logic/lib/claim/format-currency';
import getAgrIncomeThresholds from '@ahmdigital/logic/lib/utils/get-agr-income-thresholds';

import constants from '../../ahm-constants';

// @ts-ignore - Automatic, Please fix when editing this file
const getIncomeTierTextWithRange = (isCompact, incomeTierData) => {
  const { incomeTier } = incomeTierData;

  if (incomeTier === constants.INCOME_TIER.TIER_0) {
    // @ts-ignore - Automatic, Please fix when editing this file
    const formattedAmount = `${formatCurrency(get('max', incomeTierData), {
      minimumFractionDigits: 0,
    })}`;
    return isCompact ? `<${formattedAmount}` : `${formattedAmount} or less`;
  }

  if (incomeTier === constants.INCOME_TIER.TIER_3) {
    // @ts-ignore - Automatic, Please fix when editing this file
    const formattedAmount = `${formatCurrency(get('min', incomeTierData), {
      minimumFractionDigits: 0,
    })}`;
    return isCompact ? `>${formattedAmount}` : `${formattedAmount} or more`;
  }

  // @ts-ignore - Automatic, Please fix when editing this file
  return `${formatCurrency(get('min', incomeTierData), {
    minimumFractionDigits: 0,
    // @ts-ignore - Automatic, Please fix when editing this file
  })} - ${formatCurrency(get('max', incomeTierData), { minimumFractionDigits: 0 })}`;
};

// @ts-ignore - Automatic, Please fix when editing this file
const formatRate = (rate) => (rate > 1 ? `${rate}%` : `${rate}.0%`);

const getIncomeTierOptions = ({ forceDate = null, incomeType = constants.INCOME_TYPE.COUPLE, isCompact = false }) => {
  // @ts-ignore - Automatic, Please fix when editing this file
  const agrIncomeThresholds = getAgrIncomeThresholds(forceDate)[incomeType];
  const translatedTiers = map(
    (tier) => ({
      rate: formatRate(tier.rate),
      text: getIncomeTierTextWithRange(isCompact, tier),
      value: tier.incomeTier,
    }),
    agrIncomeThresholds,
  );
  return translatedTiers;
};

export default getIncomeTierOptions;
