import cookie from 'cookie';

import browserUtil from '../../src/client/utils/browser';
import config from '../../src/client/config';

const COOKIE_KEY = 'gbClientId';

const getAnalyticsClientId = () =>
  new Promise((resolve) => {
    // @ts-ignore - Automatic, Please fix when editing this file
    browserUtil.getWindow().gtag('get', config.get('google:analytics:ga4TrackingId'), 'client_id', (clientId) => {
      // Store the ga client id in a separate cookie so it can be easily accessed from the
      // server, rather than trying to interrogate the actual _ga cookie (which is not recommended).
      const document = browserUtil.getDocument();
      // @ts-ignore - Automatic, Please fix when editing this file
      document.cookie = cookie.serialize(COOKIE_KEY, clientId, { path: '/' });
      resolve(clientId);
    });
  });

export default getAnalyticsClientId;
