import { Clickable, P } from '@ahmdigital/ui';
import Icon from '@ahmdigital/icons-web';
import React from 'react';

import { successMessage } from './constants';
import RequestACallWrapper from './wrapper';
import styles from './styles.module.sass';

type RequestACallSuccessProps = {
  actions: {
    requestACall?: {
      dismiss?: (...args: unknown[]) => unknown;
    };
  };
};

const RequestACallSuccess = ({ actions }: RequestACallSuccessProps) => {
  // @ts-ignore - Automatic, Please fix when editing this file
  const onDismiss = () => actions.requestACall.dismiss();

  return (
    // @ts-ignore - Automatic, Please fix when editing this file
    <RequestACallWrapper className={styles.captured}>
      <P data-testid="leadCaptureSuccessMsg">{successMessage}</P>
      <Clickable className={styles.dismiss} onClick={onDismiss}>
        <Icon name="cross" />
      </Clickable>
    </RequestACallWrapper>
  );
};

export default RequestACallSuccess;
