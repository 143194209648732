import { find, get, isNull, toString } from 'lodash/fp';
import { RELATIONSHIP } from '@ahmdigital/constants';

import calculateAgeFromDateOfBirth from '../calculate-age-from-date-of-birth';

/**
 * Get the age of the member.
 * Only support ‘RELATIONSHIP.CLIENT’ and ‘RELATIONSHIP.SPOUSE’ since other relationships can have multiple people.
 *
 * @param {object} opts - Input options.
 * @param {object} opts.customer - Customer from the state.
 * @param {string} opts.relationship - Customer from the state.
 * @throws Error - Unsupported relationship.
 */
// @ts-ignore - Automatic, Please fix when editing this file
const getMemberAge = ({ customer, relationship }) => {
  if (relationship !== RELATIONSHIP.CLIENT && relationship !== RELATIONSHIP.SPOUSE) {
    throw new Error('Unsupported relationship');
  }
  const members = get('members', customer);
  const member = find({ relationship }, members);
  const age = calculateAgeFromDateOfBirth(get('dob', member));
  return isNull(age) ? null : toString(age);
};

export default getMemberAge;
