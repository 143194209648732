import {
  compact,
  defaults,
  filter,
  flow,
  get,
  includes,
  isPlainObject,
  isUndefined,
  lowerCase,
  map,
  partial,
  pick,
  some,
  sortBy,
  values,
} from 'lodash/fp';
import assert from 'assert';
import getParentProductId from '@ahmdigital/logic/lib/utils/get-parent-product-id';

import combineProductNames from '../../utils/combine-product-names';
import constants from '../../ahm-constants';
import getSaleableProductIdsKeyedByType from '../../utils/get-saleable-product-ids-keyed-by-type';
import productData from './data';

type Options = { scale?: string; state?: string };

const ProductService = {
  // @ts-ignore - Automatic, Please fix when editing this file
  content: function content(productId, path) {
    // Look to see if a productId has a parentProductId.
    const parentProductId = getParentProductId(productId);

    // Lookup this product’s content and find the path value from the content object
    return get(`${parentProductId || productId}.${path}`, productData) || null;
  },
  // @ts-ignore - Automatic, Please fix when editing this file
  filterAvailableProducts: function filterAvailableProducts(products, options: Options = {}) {
    const { scale, state } = options;

    const filteredProducts = filter((product) => {
      const stateOk = isUndefined(state) || (state && ProductService.isAvailableIn(product, state));
      const scaleOk = isUndefined(scale) || (scale && ProductService.isAvailableFor(product, scale));
      return stateOk && scaleOk;
    }, products);

    return filteredProducts;
  },
  getCartObject: flow(
    pick(['hospitalTier', 'id', 'name', 'rank', 'scales', 'type']),
    defaults({
      id: null,
      name: null,
      rank: null,
      scales: null,
      type: null,
    }),
  ),
  // @ts-ignore - Automatic, Please fix when editing this file
  getCombinedCartObject: ({ hospital, extras }) => ({
    // @NOTE: scales come from the hospital product because of legacy issues with extras scales.
    // E.g. new hospital products support couple (but old extras dont),
    // but the combined new hospital and extras does support couple.
    // Therefore just using the hospital scales fits our needs.
    hospitalTier: hospital.hospitalTier,
    id: `${hospital.id}-${extras.id}`,
    name: combineProductNames(hospital, extras),
    // rank can’t be merged together into a single value.
    scales: hospital.scales,
    type: constants.PRODUCT_TYPE.COMBINED,
  }),
  // @ts-ignore - Automatic, Please fix when editing this file
  getCombinedId: (product1, product2) => {
    const { EXTRAS, HOSPITAL } = constants.PRODUCT_TYPE;
    assert(includes(get('type', product1), [EXTRAS, HOSPITAL]), 'product1 must be a hospital or extras product');
    assert(includes(get('type', product2), [EXTRAS, HOSPITAL]), 'product2 must be a hospital or extras product');
    assert(product1.type !== product2.type, `Products should not be of the same type: '${product1.type}'`);
    const hospital = product1.type === HOSPITAL ? product1 : product2;
    const extras = product1.type === EXTRAS ? product1 : product2;
    return `${hospital.id}-${extras.id}`;
  },
  // @ts-ignore - Automatic, Please fix when editing this file
  getComplementaryProductIds: function getComplementaryProductIds(product) {
    assert(isPlainObject(product), `Product should be an object. Product passed: ${product}`);
    const complementaryType = ProductService.getComplementaryType(product);
    // @ts-ignore - Automatic, Please fix when editing this file
    return getSaleableProductIdsKeyedByType()[complementaryType];
  },
  // @ts-ignore - Automatic, Please fix when editing this file
  getComplementaryProducts: (product, allProducts) => {
    const complementaryProductIds = ProductService.getComplementaryProductIds(product);

    const getProduct = partial(ProductService.getProduct, [allProducts]);

    const complementaryProducts = flow(
      map(getProduct),
      compact, // remove any products that haven’t loaded yet.
      sortBy('rank'),
    )(complementaryProductIds);
    return complementaryProducts;
  },
  // @ts-ignore - Automatic, Please fix when editing this file
  getComplementaryType: function getComplementaryType(product) {
    if (product.type === constants.PRODUCT_TYPE.HOSPITAL) {
      return constants.PRODUCT_TYPE.EXTRAS;
    }

    if (product.type === constants.PRODUCT_TYPE.EXTRAS) {
      return constants.PRODUCT_TYPE.HOSPITAL;
    }

    return null;
  },

  /**
   * Builds a sentence to describe the daily charge costs associated with the selected product and scale.
   *
   * @param {object} product - Selected product.
   * @param {string} scale -   - Selected customer scale.
   * @returns {string}       - Sentence describing daily charge costs.
   */
  // @ts-ignore - Automatic, Please fix when editing this file
  getDailyChargeText: function getDailyChargeText(product, scale) {
    if (!product.coPayment) {
      return '';
    }

    const {
      coPayment: { limits, value },
    } = product;

    // Family cap information shows if the scale is NOT family
    const familyCapInformation =
      scale !== constants.SCALE.FAMILY
        ? ` and the family cap is $${limits[constants.SCALE.FAMILY]} each membership year`
        : '';
    return (
      `You’re on a $${value} daily charge per person as a ` +
      `${lowerCase(constants.SCALE_LABEL[scale])} with a cap of ` +
      `$${limits[scale]}${familyCapInformation}.`
    );
  },

  /**
   * Get a product, with caching.
   *
   * @param {Array} products - Products to pick from.
   * @param {string} productId - Product id to get.
   * @returns {object} Product that matches.
   */
  // @ts-ignore - Automatic, Please fix when editing this file
  getProduct: (products, productId) => get(productId, products),

  /**
   * Get products.
   *
   * @param {Array} products - Products to pick from.
   * @param {Array} productIds - Product ids to get.
   * @returns {Array} Products that match.
   */
  // @ts-ignore - Automatic, Please fix when editing this file
  getProducts: (products, productIds) => flow(pick(productIds), values)(products),
  // @ts-ignore - Automatic, Please fix when editing this file
  isAvailableFor: (product, scale) => includes(scale, get('scales.selectable', product)),
  // TODO: [HER-3644] Cleanup `isAvailableIn` code as Ambulance isn't saleable on the site
  // @ts-ignore - Automatic, Please fix when editing this file
  isAvailableIn: function isAvailableIn(product, state) {
    if (product.id === constants.PRODUCT.AMBULANCE) {
      return state === constants.STATE.NSW || state === constants.STATE.ACT;
    }

    return true;
  },

  /**
   * Load a product from the API.
   *
   * @param {object} api - The api to request the product from.
   * @param {string} productId - The product id to load.
   * @returns {promise} A promise that resolves when the product has loaded.
   */
  // @ts-ignore - Automatic, Please fix when editing this file
  loadProduct: function loadProduct(api, productId) {
    const isProductIdValid = some((validProductId) => validProductId === productId, constants.PRODUCT);

    if (!isProductIdValid) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: `Invalid ProductId provided: '${productId}'`,
        type: 'ProductIdMissedError',
      });
    }

    const key = productId;

    // @ts-ignore - Automatic, Please fix when editing this file
    if (!ProductService.promises[key]) {
      // @ts-ignore - Automatic, Please fix when editing this file
      ProductService.promises[key] = api.get(`/products/${productId}`).then((json) => json.product);
    }

    // @ts-ignore - Automatic, Please fix when editing this file
    return ProductService.promises[key];
  },
  // @ts-ignore - Automatic, Please fix when editing this file
  loadProducts: function loadProducts(api, productIds) {
    const fn = partial(ProductService.loadProduct, [api]);

    const promise = Promise.all(map(fn, productIds));
    promise.catch(() => ProductService.reset());
    return promise;
  },
  promises: {},
  reset: function reset() {
    ProductService.promises = {};
  },
};
export default ProductService;
