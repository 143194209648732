import { ANALYTICS } from '@ahmdigital/constants';
import { ConnectNext, Spinner, theme } from '@ahmdigital/ui';
import { get, replace, snakeCase, split, toLower } from 'lodash/fp';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { NextSeo } from 'next-seo'; // eslint-disable-line lodash/import-scope
// @ts-ignore - Automatic, Please fix when editing this file
import { PersistGate as PersistGateClient } from 'redux-persist/lib/integration/react';
import { REHYDRATE } from 'redux-persist';
import { ThemeProvider } from 'styled-components';
import { useRouter } from 'next/router';
import { useStore } from 'react-redux';
// @ts-ignore - Automatic, Please fix when editing this file
import liveChatActions from '@ahmdigital/livechat/lib/actions';
import loggerFactory from '@ahmdigital/logger-frontend';
import NextImage from 'next/future/image';
import NextLink from 'next/link';
import React, { useEffect } from 'react';
import Script from 'next/script';

import '../src/client/application/base/index.sass';
import '@ahmdigital/ui/lib/style.css';
import { nextReduxWrapper } from '../src/client/store/create-store';
import analytics from '../src/client/analytics';
import Application from '../src/client/application';
import browserUtil from '../src/client/utils/browser';
import config from '../src/client/config';
import createHistory from '../src/client/store/create-history';
import CustomApolloProvider from '../src/client/client-app/custom-apollo-provider';
import CustomRouter from '../src/client/components/custom-router';
import FaviconHeader from './favicon-header';
import GA4Analytics from '../src/client/analytics/ga4-analytics';
import getAnalyticsClientId from '../utils/get-analytics-client-id';
import getIsProductionEnv from '../src/client/utils/get-is-production-env';
import getIsServer from '../src/client/utils/get-is-server';
import liveChatConfig from '../src/client/utils/livechat';
import LocationActions from '../src/client/actions/location';
import logging from '../src/client/logging';
import makeGetCustomData from '../src/client/utils/make-get-custom-data';
import ProductReviewScriptProvider from '../src/client/application/scripts/product-review-widget-provider';
import ScrollToView from '../src/utils/scroll-to-view';
import versionInfo from '../.version.json';

// @ts-ignore - Automatic, Please fix when editing this file
const buildLogging = (reduxStore) => {
  const getCustomData = makeGetCustomData({ reduxStore });
  // We are using versionInfo as the default export
  // because using named exports from JSON modules is not supported in Webpack 5.
  const { version } = versionInfo;

  const logger = loggerFactory(config.get('loggingClientSide'), {
    React,
    application: config.get('app'),
    getCustomData,
    releaseStage: config.get('NODE_ENV'),
    version,
  });
  logging.setLogger(logger);
  return logging;
};

// Fixes a persist gate issue with static optimisation
// https://github.com/rt2zz/redux-persist/issues/576
// @ts-ignore - Automatic, Please fix when editing this file
const PersistGateServer = ({ children }) => children;

const gb = new GrowthBook({
  apiHost: config.get('growthbook:apiHost'),
  clientKey: config.get('growthbook:clientKey'),
  // Enable easier debugging of feature flags during development
  enableDevMode: !getIsProductionEnv(),
  trackingCallback: (experiment, result) => {
    // Note, using browserUtil here for the moment, rather than TagManager.submit as this
    // callback can run server side and TagManager.submit is expecting the window to be
    // defined.
    // @ts-ignore - Automatic, Please fix when editing this file
    browserUtil.getWindow().gtag('event', ANALYTICS.EVENTS.ECOMMERCE_GA4.VIEWED_EXPERIMENT, {
      /* eslint-disable camelcase */
      event_category: 'experiment',
      experiment_id: experiment.key,
      send_to: config.get('google:analytics:ga4TrackingId'),
      variation_id: result.variationId,
      /* eslint-enable camelcase */
    });
  },
});

type AppProps = {
  Component: React.ComponentType;
  pageProps?: {};
};

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { asPath } = router;
  const history = createHistory(asPath);
  const isServer = getIsServer();
  const store = useStore();
  // @ts-ignore - Automatic, Please fix when editing this file
  store.dispatch(LocationActions.load());
  analytics.getState = store.getState;

  useEffect(() => {
    // @ts-ignore - Automatic, Please fix when editing this file
    const handleRouteChange = (url) => {
      analytics.publish('pageview', { location: url });
    };

    // Record the pageview event for the initial page load
    analytics.publish('pageview', { location: asPath });

    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Load features from the GrowthBook API and keep them up-to-date
    gb.loadFeatures({ autoRefresh: true });
  }, []);

  useEffect(() => {
    const getClientId = async () => {
      const clientId = await getAnalyticsClientId();
      const url = window.location.href;
      const urlNoProtocol = replace(/^https?:\/\//i, '', url);
      gb.setAttributes({
        id: clientId,
        url: urlNoProtocol,
      });
    };
    getClientId();
  }, [asPath]);

  const PersistGate = isServer ? PersistGateServer : PersistGateClient;

  // this function will be called once the livechat action has been reduced into state
  // so we need to assign new values from the redux state into GA‘s dimensions
  // and then fire an action to analytics.
  // @ts-ignore - Automatic, Please fix when editing this file
  const publishChatState = (data) => {
    analytics.publish('profile:assign');
    analytics.publish('action', {
      action: snakeCase(`on_page_chat_${data.state}`),
      category: 'chat',
      label: data.conversationId,
    });
  };

  // @ts-ignore - Automatic, Please fix when editing this file
  const submitChatStateToGA4 = (liveChatState) => {
    GA4Analytics.submit({
      data: liveChatState,
      name: 'on_page_chat',
    });
  };

  // ART-1698: Fix the analytics event by forcing a rehydration event on new page load
  if (!isServer) {
    store.dispatch({ type: REHYDRATE });
  }

  const CANONICAL_DOMAIN = 'https://ahm.com.au';
  const canonicalFragments = split('?', CANONICAL_DOMAIN + (router.asPath === '/' ? '' : router.asPath));
  // @ts-ignore - Automatic, Please fix when editing this file
  const canonicalUrl = toLower(canonicalFragments[0]);

  const ErrorBoundary = buildLogging(store).getLogger().getErrorBoundary();

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        {/* eslint-disable-next-line no-underscore-dangle */}
        <PersistGate loading={<Spinner />} persistor={get('__persistor', store)}>
          <GrowthBookProvider growthbook={gb}>
            <CustomRouter history={history}>
              <ConnectNext hasNextJs nextLink={NextLink} nextImage={NextImage} nextRouter={router}>
                <CustomApolloProvider>
                  <FaviconHeader />
                  <ProductReviewScriptProvider>
                    <Application>
                      <Script id="lazyLoaded" strategy="lazyOnload">
                        {!isServer
                          ? liveChatActions.load(
                              liveChatConfig,
                              undefined,
                              publishChatState,
                              submitChatStateToGA4,
                            )(store.dispatch)
                          : null}
                      </Script>

                      <Script id="anchor-scroll" strategy="afterInteractive">
                        {setTimeout(ScrollToView, 1000)}
                      </Script>

                      <NextSeo defaultTitle="Cheap private health insurance" canonical={canonicalUrl} />

                      {/* @ts-ignore - Automatic, Please fix when editing this file */}
                      <Component {...pageProps} history={history} key={router.asPath} />
                    </Application>
                  </ProductReviewScriptProvider>
                </CustomApolloProvider>
              </ConnectNext>
            </CustomRouter>
          </GrowthBookProvider>
        </PersistGate>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

App.defaultProps = {
  pageProps: null,
};

export default nextReduxWrapper.withRedux(App);
