import { get, split } from 'lodash/fp';

import browser from './utils/browser';
import getIsServer from './utils/get-is-server';

/*
 * App config manager
 *
 * Idea behind it is to have same way to use config variables on both
 * client and server. It is only the case for code under app/ directory,
 * which supposed to work on both client and server. Server-specific
 * components (e.g. express middleware) should use normal @ahmdigital/config.
 *
 * Current config structure:
 * {
 *   "foo": "bar",
 *   "baz": "s3cr3t"
 *   "shared": ["foo"]
 * }
 *
 * Based on that, only { "foo": "bar" } will be shared with client.
 *
 * Later on we can consider to switch to following config structure:
 * {
 *   "server": {
 *     "secret": "s3cr3t",
 *     "endpoint": "http://localhost:3000/api"
 *   },
 *   "shared": {
 *     "maintenance": true,
 *   },
 *   "client": {
 *     "endpoint": "http://localhost:3000/api"
 *   }
 * }
 * So that on server config is a merge of "server" + "shared", on client it is "client" + "shared".
 */

const DELIM = ':'; // Standard nconf path delimiter
const isServer = getIsServer();

const config = {
  // @ts-ignore - Automatic, Please fix when editing this file
  data: (isServer ? global.__config : browser.getWindow().__config) || process.env.config,
  // @ts-ignore - Automatic, Please fix when editing this file
  get(key) {
    return get(split(DELIM, key), this.data);
  },
};

export default config;
