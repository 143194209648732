import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ConnectAnalytics } from '@ahmdigital/ui';
import { ConnectedRouter as ConnectedNextRouter } from 'connected-next-router';
import { isUndefined } from 'lodash/fp';
import React, { useEffect } from 'react';

import analytics from '../../analytics';
import DrawerActions from '../../actions/drawer';
import getIsServer from '../../utils/get-is-server';
import getMemberNumber from '../../utils/get-oms-member-number';
import HistoryActions from '../../actions/history';
import ModalActions from '../../actions/modal';
import RequestACall from '../../actions/request-a-call';
import useProductPageOptimisation from '../../pages/product/util/use-product-page-optimisation';

type CustomRouterProps = {
  actions: {
    drawer: {
      closeDrawer: (...args: unknown[]) => unknown;
    };
    history: {
      push: (...args: unknown[]) => unknown;
    };
    modal: {
      closeModal: (...args: unknown[]) => unknown;
    };
    requestACall: {
      checkRules: (...args: unknown[]) => unknown;
    };
  };
  children: React.ReactNode;
  history: {
    listen: (...args: unknown[]) => unknown;
    location: {
      pathname: string;
    };
  };
};

const CustomRouter = ({ actions, children, history }: CustomRouterProps) => {
  const showOptimisedProductPage = useProductPageOptimisation();
  // @ts-ignore - Automatic, Please fix when editing this file
  useEffect(() => {
    // @ts-ignore - Automatic, Please fix when editing this file
    const historyEventHandler = (location) => {
      const isServer = getIsServer();
      if (!isServer && !location.hash) {
        actions.history.push(location.pathname);

        const isNotAMember = isUndefined(getMemberNumber());
        if (isNotAMember) {
          actions.requestACall.checkRules(showOptimisedProductPage);
        }
      }
    };

    // Run this once the first time the component loads
    historyEventHandler(history.location);

    // We return the result of this because it returns a function that removes the event listener, which this hook will call on unmount
    // @ts-ignore - Automatic, Please fix when editing this file
    return history.listen((location) => {
      if (!location.hash) {
        window.scrollTo(0, 0);
        document.body.focus();
      }

      actions.modal.closeModal();
      actions.drawer.closeDrawer();

      historyEventHandler(location);
    });
  }, [actions, history]);

  return (
    <ConnectAnalytics publish={analytics.publish}>
      {/* @ts-ignore - Automatic, Please fix when editing this file */}
      <ConnectedNextRouter history={history}>{children}</ConnectedNextRouter>
    </ConnectAnalytics>
  );
};

// @ts-ignore - Automatic, Please fix when editing this file
const mapDispatchToProps = (dispatch) => ({
  actions: {
    drawer: bindActionCreators(DrawerActions, dispatch),
    history: bindActionCreators(HistoryActions, dispatch),
    modal: bindActionCreators(ModalActions, dispatch),
    requestACall: bindActionCreators(RequestACall, dispatch),
  },
});

// @ts-ignore - Automatic, Please fix when editing this file
export default connect(null, mapDispatchToProps)(CustomRouter);

export { CustomRouter as CustomRouterForTest, mapDispatchToProps };
