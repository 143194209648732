import { gql } from '@apollo/client';

import { GRAPHQL } from '../constants';
import { INSURANCE_PAGE_LISTING_FRAGMENT } from '../../pages/insurance/queries';

export const GET_ALL_BANNER_QUERY = gql`
  query banners {
    allBanner {
      name
      identifier {
        current
      }
      listItems {
        name
        identifier {
          current
        }
        ${INSURANCE_PAGE_LISTING_FRAGMENT}
      }
    }
  }
`;

export const PERK_QUERY = `
  _updatedAt
  _id
  id: _id
  category
  descriptionTeaser
  ${GRAPHQL.SLUG}
  offeredBy {
    name
    logo {
      asset {
        _id
        path
      }
    }
    legalName
  }
  ${GRAPHQL.IMAGE_OBJECT}
  name
  ${GRAPHQL.RELEASE}
  slogan
  termsAndConditionsRaw
  ${GRAPHQL.URL_OBJECT}
`;

export const GET_ALL_PERKS_QUERY = gql`
  {
    allMemberPerk {
      ${PERK_QUERY}
    }
  }
`;

export const GET_CONTACT_QUERY = gql`
  query GetContact($identifier: String!) {
    allContact(where: { identifier: { current: { eq: $identifier } } }) {
      _id
      action
      descriptionRaw
      phoneNumber
    }
  }
`;
