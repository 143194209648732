import { CLOSE_TOAST, OPEN_TOAST, SET_CONTENT_TOAST } from '../action-types';

const setToastContent =
  // @ts-ignore - Automatic, Please fix when editing this file


    ({ content, id }) =>
    // @ts-ignore - Automatic, Please fix when editing this file
    (dispatch) =>
      dispatch({
        payload: {
          content,
          id,
        },
        type: SET_CONTENT_TOAST,
      });

// @ts-ignore - Automatic, Please fix when editing this file
const openToast = (id) => (dispatch) =>
  dispatch({
    payload: { id },
    type: OPEN_TOAST,
  });

// @ts-ignore - Automatic, Please fix when editing this file
const closeToast = (id) => (dispatch) =>
  dispatch({
    payload: { id },
    type: CLOSE_TOAST,
  });

export default {
  closeToast,
  openToast,
  setToastContent,
};
