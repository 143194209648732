import { flow, getOr, set, unset } from 'lodash/fp';

import type { LeadFormData } from '../../types';

// @ts-ignore - Automatic, Please fix when editing this file
const adjustFormValuesForLeadType = ({ leadType, values }) => {
  const marketingOptIn = getOr(false, `marketingOptIn-${leadType.component}`, values);
  const privacyAgreement = getOr(false, `privacyAgreement-${leadType.component}`, values);
  return flow(
    set('marketingOptIn', marketingOptIn),
    unset(`marketingOptIn-${leadType.component}`),
    set('privacyAgreement', privacyAgreement),
    unset(`privacyAgreement-${leadType.component}`),
  )(values) as LeadFormData;
};

export default adjustFormValuesForLeadType;
