import { AnnouncementBanner, theme } from '@ahmdigital/ui';
import { filter, first, flow, map } from 'lodash/fp';
import React from 'react';

import { FRAGMENTS } from '../../cms/constants';
import useTableOfContents from '../../cms/utils/use-table-of-contents';

const ALERT_LEVELS = {
  danger: theme.colors.redTint,
  info: theme.colors.blueTint,
  success: theme.colors.greenTint,
  warning: theme.colors.yellowTint,
};

// @ts-ignore - Automatic, Please fix when editing this file
export const getAnnouncementBackgroundColor = (level = 'info') => ALERT_LEVELS[level] || theme.colors.blueTint;

const CMSAnnouncementBanner = () => {
  // @ts-ignore - Automatic, Please fix when editing this file
  const { items: alertData } = useTableOfContents({
    fragment: FRAGMENTS.ANNOUNCEMENTS,
    slug: 'sales-banner',
  });

  const alertItem = flow(map('item'), filter({ _type: 'alert' }), first)(alertData);
  if (!alertItem) {
    return null;
  }

  return (
    <AnnouncementBanner
      // @ts-ignore - Automatic, Please fix when editing this file
      message={alertItem?.descriptionRaw}
      // @ts-ignore - Automatic, Please fix when editing this file
      backgroundColor={getAnnouncementBackgroundColor(alertItem?.level)}
    />
  );
};

export default CMSAnnouncementBanner;
