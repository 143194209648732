import { get, getOr, noop } from 'lodash/fp';
import { useEffect, useState } from 'react';

import getIsServer from '../../../../../../utils/get-is-server';

// Adapted from https://usehooks-typescript.com/react-hook/use-intersection-observer
const useIntersectionObserver = (
  // @ts-ignore - Automatic, Please fix when editing this file
  elementRef,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false } = {},
) => {
  const isServer = getIsServer();
  const hasIOSupport = !isServer && Boolean(window.IntersectionObserver);

  const [entry, setEntry] = useState();
  const isFrozen = getOr(false, 'isIntersecting', entry) && freezeOnceVisible;

  // @ts-ignore - Automatic, Please fix when editing this file
  const updateEntry = ([newEntry]) => {
    setEntry(newEntry);
  };

  const node = get('current', elementRef);
  useEffect(() => {
    if (!hasIOSupport || isFrozen || !node) {
      return noop;
    }

    const observerParams = { root, rootMargin, threshold };
    // @ts-ignore - Automatic, Please fix when editing this file
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);

    return () => observer.disconnect();
  }, [hasIOSupport, isFrozen, node, root, rootMargin, threshold]);

  return { entry, isSupported: hasIOSupport };
};

export default useIntersectionObserver;
