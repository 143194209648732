import moment from 'moment';

import constants from '../../ahm-constants';

/**
 * @param {string=} dateOfBirth - A customer's dateOfBirth.
 *
 * @returns {number|null} - The age of the customer.
 */
// @ts-ignore - Automatic, Please fix when editing this file
const calculateAgeFromDateOfBirth = (dateOfBirth) => {
  if (!dateOfBirth) {
    return null;
  }
  const dateOfBirthMoment = moment(dateOfBirth, constants.DATE_FORMAT.STANDARD);
  return moment().diff(dateOfBirthMoment, 'years');
};

export default calculateAgeFromDateOfBirth;
