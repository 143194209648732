import { concat, find, head, includes, map, split, startsWith } from 'lodash/fp';

import constants from '../../../../ahm-constants';

const {
  SALES: {
    CAR_AND_HOME_INSURANCE_PRIVACY_POLICY,
    CAR_INSURANCE_LOGIN,
    CAR_INSURANCE_QUOTE,
    CAR_INSURANCE_SUCCESS,
    CAR_INSURANCE,
    CONTACT_US,
    HOME_INSURANCE_LOGIN,
    HOME_INSURANCE_QUOTE,
    HOME_INSURANCE_SUCCESS,
    HOME_INSURANCE,
    LIFE_INSURANCE_CLAIM,
    LIFE_INSURANCE_CONTACT_US,
    LIFE_INSURANCE,
    LIVEPERSON_UNAUTHENTICATED,
    OFFER_REFER,
    PRODUCT_LISTING,
    PRODUCT_LISTING_EXTRAS,
    PRODUCT_LISTING_HOSPITAL,
    PRODUCT_LISTING_PACKAGES,
    TRAVEL_INSURANCE_CONTACT_US,
    TRAVEL_INSURANCE_MAKE_A_CLAIM,
    TRAVEL_INSURANCE,
  },
} = constants.URLS;

const requestACallDenylist = [
  CAR_AND_HOME_INSURANCE_PRIVACY_POLICY,
  CAR_INSURANCE_LOGIN,
  CAR_INSURANCE_QUOTE,
  CAR_INSURANCE_SUCCESS,
  CAR_INSURANCE,
  CONTACT_US,
  HOME_INSURANCE_LOGIN,
  HOME_INSURANCE_QUOTE,
  HOME_INSURANCE_SUCCESS,
  HOME_INSURANCE,
  LIFE_INSURANCE_CLAIM,
  LIFE_INSURANCE_CONTACT_US,
  LIFE_INSURANCE,
  LIVEPERSON_UNAUTHENTICATED,
  OFFER_REFER,
  TRAVEL_INSURANCE_CONTACT_US,
  TRAVEL_INSURANCE_MAKE_A_CLAIM,
  TRAVEL_INSURANCE,
];
// @ts-ignore - Automatic, Please fix when editing this file
const stripAllFromUrl = (urls) => map((url: string) => head(split('/all', url)), urls);

const PRODUCT_LISTING_PAGES_SHOWING_ALL = [PRODUCT_LISTING_EXTRAS, PRODUCT_LISTING_HOSPITAL, PRODUCT_LISTING_PACKAGES];
const PRODUCT_PAGE_BASE_URLS = concat(
  `${PRODUCT_LISTING}/hospital-extras-bundle`,
  stripAllFromUrl(PRODUCT_LISTING_PAGES_SHOWING_ALL),
);

/**
 * @param {string} url - The url to check.
 * @param {boolean} showOptimisedProductPage - Indicates if the b-variant (of the showOptimisedProductPage a/b test) is active.
 * @returns {boolean} - True when the page is denylisted.
 */
// @ts-ignore - Automatic, Please fix when editing this file
const isRequestACallDenylistPage = (url, showOptimisedProductPage = false) => {
  if (includes(url, requestACallDenylist)) {
    return true;
  }

  // The standard request a call bubble is not to be shown on optimised product details pages.
  if (showOptimisedProductPage) {
    if (includes(url, PRODUCT_LISTING_PAGES_SHOWING_ALL)) {
      return false;
    }

    // @ts-ignore - Automatic, Please fix when editing this file
    return Boolean(find((baseUrl) => startsWith(baseUrl, url), PRODUCT_PAGE_BASE_URLS));
  }
  return false;
};

export default isRequestACallDenylistPage;
