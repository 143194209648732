import { compact, concat, get, includes } from 'lodash/fp';

import constants from '../../ahm-constants';
import getAppleBusinessChatData from '../../utils/get-apple-business-chat-data';
import liveChatConfig from '../../utils/livechat';

const healthInsuranceMenuItems = {
  linkTo: constants.URLS.SALES.HEALTH_INSURANCE,
  subItems: [
    { linkTo: constants.URLS.SALES.REFINE_PRICE, text: 'Find cover' },
    { linkTo: constants.URLS.SALES.PRODUCT_LISTING_HOSPITAL, text: 'Compare hospital' },
    { linkTo: constants.URLS.SALES.PRODUCT_LISTING_EXTRAS, text: 'Compare extras' },
    { linkTo: constants.URLS.SALES.PRODUCT_LISTING_PACKAGES, text: 'Compare packages' },
    { linkTo: constants.URLS.SALES.PREGNANCY, text: 'Pregnancy' },
    { linkTo: constants.URLS.SALES.TAX, text: 'Tax & hospital cover' },
    { icon: 'external-link-light', linkTo: constants.URLS.OSHC.HOME, text: 'Overseas students' },
  ],
};

const subItemsWithSoftBundle = [...healthInsuranceMenuItems.subItems];
subItemsWithSoftBundle.splice(4, 0, {
  // TODO add URLS constant once available
  linkTo: '/chooseyourbundle',
  text: 'Choose your own hospital & extras',
});
const healthInsuranceMenuItemsWithSoftBundle = {
  ...healthInsuranceMenuItems,
  subItems: subItemsWithSoftBundle,
};

const featuresMenuItems = {
  linkTo: constants.URLS.SALES.OFFER_HOME,
  subItems: [
    { linkTo: constants.URLS.SALES.OFFER_HOME, text: 'Latest offers' },
    { linkTo: constants.URLS.SALES.NO_GAP_DENTAL, text: 'No gap dental' },
    { linkTo: constants.URLS.SALES.YOUTH_DISCOUNT, text: 'Youth discount' },
  ],
};

const perksMenuItems = {
  id: 'perks',
  linkTo: constants.URLS.SALES.OFFERS_HOME,
  subItems: [
    {
      icon: 'chevron-right-light',
      linkTo: constants.URLS.SALES.OFFERS_HOME,
      text: 'See all perks',
    },
  ],
  variants: ['tabInline'],
};

const rightSideMenuItems = [
  {
    linkTo: constants.URLS.HELP.HOME,
    position: 'right',
    text: 'help',
  },
  {
    linkTo: constants.URLS.SALES.CONTACT_US,
    position: 'right',
    text: 'contact us',
  },
  {
    linkTo: `tel:${constants.CONTACT.PHONE.STANDARD}`,
    mobileOnly: true,
    position: 'right',
    text: 'call us now',
  },
];

const generateMenuItems = (showSoftBundle: boolean) =>
  compact(
    concat(
      [
        {
          linkTo: constants.URLS.SALES.PRODUCT_LISTING,
          subItemGroups: [
            {
              ...(showSoftBundle ? healthInsuranceMenuItemsWithSoftBundle : healthInsuranceMenuItems),
              groupText: 'Health insurance',
            },
            {
              ...featuresMenuItems,
              groupText: 'Features',
            },
            {
              ...perksMenuItems,
              groupText: 'Perks',
            },
          ],
          text: 'health',
        },
        {
          linkTo: constants.URLS.SALES.TRAVEL_INSURANCE,
          text: 'travel',
        },
        {
          linkTo: constants.URLS.SALES.CAR_INSURANCE,
          text: 'car',
        },
        {
          linkTo: constants.URLS.SALES.HOME_INSURANCE,
          text: 'home',
        },
        {
          linkTo: constants.URLS.SALES.LIFE_INSURANCE,
          text: 'life',
        },
        {
          linkTo: constants.URLS.SALES.INCOME_INSURANCE,
          text: 'income',
        },
        {
          icon: 'external-link-light',
          linkTo: 'https://www.petinsurance.com.au/partner/ahm/',
          text: 'pet',
        },
      ],
      rightSideMenuItems,
    ),
  );

const getMenuItems = (
  actionsLiveChat: {
    launch: (liveChatConfig: {}) => void;
  },
  isAbcSupportedAndAvailable: boolean,
  liveChatStatus: number,
  location?: {
    pathname?: string;
  },
  showSoftBundle: boolean = false,
) => {
  if (includes(get('pathname', location), constants.LIVEPERSON.LIVECHAT_URL_BLACKLIST)) {
    return generateMenuItems(showSoftBundle);
  }

  const launchLiveChatWindow = () => {
    actionsLiveChat.launch(liveChatConfig);
  };

  const livechatMenuItem = {
    appleBusinessChat: getAppleBusinessChatData({ isAbcSupportedAndAvailable }),
    launchChatWindow: launchLiveChatWindow,
    mobileOnly: true,
    position: 'right',
    status: liveChatStatus,
    text: 'message us',
    variants: ['contextLiveChat'],
  };

  return [...generateMenuItems(showSoftBundle), livechatMenuItem];
};

export default getMenuItems;
