import { ApolloClient, ApolloProvider } from '@apollo/client';
import { connect } from 'react-redux';
import React from 'react';

import clientSingleton from '../../cms/client';

type CustomApolloProviderProps = {
  children: React.ReactNode;
  // @ts-ignore - Automatic, Please fix when editing this file
  client: ApolloClient;
};

const CustomApolloProvider = ({ client, children }: CustomApolloProviderProps) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

const mapStateToProps = () => ({
  client: clientSingleton.get(),
});

export default connect(mapStateToProps)(CustomApolloProvider);

export { CustomApolloProvider as CustomApolloProviderForTest, mapStateToProps };
