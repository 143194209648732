import { cloneDeep } from 'lodash/fp';
// @ts-ignore - Automatic, Please fix when editing this file
import livechat from '@ahmdigital/livechat/lib/analytics';

import { EVENTS } from './google/constants';
import browser from '../utils/browser';
import utils from './utils';

const ecommerceHandler =
  // @ts-ignore - Automatic, Please fix when editing this file


    ({ google }) =>
    // @ts-ignore - Automatic, Please fix when editing this file
    (eventName, data) => {
      const { type } = data;
      const livechatPayload = cloneDeep(data);
      const payload = {
        category: data.product.type,
        coupon: data.promoCode,
        id: data.product.id,
        // @ts-ignore - Automatic, Please fix when editing this file
        list: data.page || browser.getWindow().location.pathname,
        name: data.product.name,
        quantity: utils.getPseu(data.product.type, data.scale),
        revenue: data.revenue,
      };
      livechatPayload.price = payload.quantity;

      switch (type) {
        case 'impression':
          // @ts-ignore - Automatic, Please fix when editing this file
          payload.price = data.product.price;
          google.ecommerce(EVENTS.ECOMMERCE.ADD_IMPRESSION, payload);
          break;
        case 'select':
          google.ecommerce(EVENTS.ECOMMERCE.ADD_PRODUCT, payload);
          google.ecommerce(EVENTS.ECOMMERCE.SET_ACTION, 'click', { list: payload.list });
          break;
        case 'view':
          google.ecommerce(EVENTS.ECOMMERCE.ADD_PRODUCT, payload);
          google.ecommerce(EVENTS.ECOMMERCE.SET_ACTION, 'detail');
          livechat.publish(livechat.EVENT.VIEW_PRODUCT, livechatPayload);
          break;
        case 'add':
          google.ecommerce(EVENTS.ECOMMERCE.ADD_PRODUCT, payload);
          google.ecommerce(EVENTS.ECOMMERCE.SET_ACTION, 'add');
          break;
        case 'checkout':
          google.ecommerce(EVENTS.ECOMMERCE.ADD_PRODUCT, payload);
          google.ecommerce(EVENTS.ECOMMERCE.SET_ACTION, 'checkout', { step: 1 });
          livechat.publish(livechat.EVENT.ADD_PRODUCT, livechatPayload);
          break;
        case 'payment':
          google.ecommerce(EVENTS.ECOMMERCE.ADD_PRODUCT, payload);
          google.ecommerce(EVENTS.ECOMMERCE.SET_ACTION, 'checkout', { step: 2 });
          break;
        case 'purchase':
          google.ecommerce(EVENTS.ECOMMERCE.ADD_PRODUCT, payload);
          google.ecommerce(EVENTS.ECOMMERCE.SET_ACTION, 'purchase', {
            coupon: payload.coupon,
            id: `${payload.id}:${Date.now()}`,
            quantity: payload.quantity,
            revenue: payload.revenue,
          });
          livechat.publish(livechat.EVENT.PURCHASE, livechatPayload);
          break;
        default:
          throw new Error(`Unsupported ecommerce event type '${type}'`);
      }
    };

export default ecommerceHandler;
