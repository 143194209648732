// @ts-ignore - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';

import { PRODUCT_LISTING_OVERLAY_HIDDEN, PRODUCT_LISTING_OVERLAY_SHOWING } from '../../actions/action-types';

const getInitialState = () => ({
  isShowing: false,
});

const productListingOverlayReducer = (oldState = getInitialState(), action = {}) => {
  const state = Immutable.from(oldState);

  const reducers = {
    [PRODUCT_LISTING_OVERLAY_HIDDEN]: () => Immutable.set(state, 'isShowing', false),
    [PRODUCT_LISTING_OVERLAY_SHOWING]: () => Immutable.set(state, 'isShowing', true),
  };

  // @ts-ignore - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
};

export default productListingOverlayReducer;
