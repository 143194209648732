import { dropRight, filter, get, includes } from 'lodash/fp';

import constants from '../../../ahm-constants';

// @ts-ignore - Automatic, Please fix when editing this file
const getCustomerMemberTypes = (scale) =>
  // @ts-ignore - Automatic, Please fix when editing this file
  dropRight(!includes(scale, [constants.SCALE.COUPLE, constants.SCALE.FAMILY]), [
    constants.RELATIONSHIP.CLIENT,
    constants.RELATIONSHIP.SPOUSE,
  ]);

// @ts-ignore - Automatic, Please fix when editing this file
const filterCustomerMembers = (customer) => {
  const mainMemberTypes = getCustomerMemberTypes(get('scale', customer));
  return filter(({ relationship }) => includes(relationship, mainMemberTypes), customer.members);
};

export default filterCustomerMembers;
