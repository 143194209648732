import { isEmpty, some } from 'lodash/fp';

import logger from '../../../../logging';

// @ts-ignore - Automatic, Please fix when editing this file
const generateLiveFilteringApi = ({ asAtDateTime, dataset, endpoint, mode, token }) => {
  if (some(isEmpty, [asAtDateTime, dataset, endpoint, mode, token])) {
    logger.getLogger().error('Missing required values for live filtering api generation', {
      asAtDateTime,
      dataset,
      endpoint,
      isEmptyToken: isEmpty(token),
      mode,
    });
  }
  return `${endpoint}/${dataset}/${mode}/${asAtDateTime}?token=${token}`;
};

export default generateLiveFilteringApi;
