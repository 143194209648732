import { ResponsiveMenuDropdown } from '@ahmdigital/ui';
import React, { useState } from 'react';

import constants from '../../../../ahm-constants';

const menuItems = [
  {
    linkTo: constants.URLS.MEMBERS.HOME,
    text: 'health insurance',
  },
  {
    linkTo: constants.URLS.SALES.CAR_INSURANCE_LOGIN,
    text: 'car insurance',
  },
  {
    linkTo: constants.URLS.SALES.HOME_INSURANCE_LOGIN,
    text: 'home insurance',
  },
];

const LoginMenu = () => {
  const [isOpen, setOpen] = useState(false);

  const onToggle = () => {
    setOpen(!isOpen);
  };

  return <ResponsiveMenuDropdown title="log in" items={menuItems} isOpen={isOpen} onToggle={onToggle} />;
};

export default LoginMenu;
