import { getAgrBracketFromAges } from '../../selectors/select-age-bracket';
import { getCustomerAges } from '../../selectors/select-youth-discount-ages';

/**
 * @param {object} customer - A customer.
 * @returns {string} - An AGR bracket from REBATE_AGE_BRACKET.
 */
// @ts-ignore - Automatic, Please fix when editing this file
const getCustomerAgeBracket = (customer) => getAgrBracketFromAges(getCustomerAges(customer));

export default getCustomerAgeBracket;
