import { get } from 'lodash/fp';

// @ts-ignore - Automatic, Please fix when editing this file
const combineProductNames = (product, softBundleComplementaryProduct) => {
  const baseProductName = get('name', product);
  const softBundleComplementaryProductName = get('name', softBundleComplementaryProduct);
  return `${baseProductName} & ${softBundleComplementaryProductName}`;
};

export default combineProductNames;
