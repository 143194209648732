import { Clickable } from '@ahmdigital/ui';
import Icon from '@ahmdigital/icons-web';
import React from 'react';

import { failedMessage } from './constants';
import RequestACallWrapper from './wrapper';
import styles from './styles.module.sass';

type RequestACallFailProps = {
  actions: {
    requestACall?: {
      dismiss: (...args: unknown[]) => unknown;
    };
  };
};

const RequestACallFail = ({ actions }: RequestACallFailProps) => {
  // @ts-ignore - Automatic, Please fix when editing this file
  const onDismiss = () => actions.requestACall.dismiss();

  return (
    // @ts-ignore - Automatic, Please fix when editing this file
    <RequestACallWrapper className={styles.captured}>
      {failedMessage}
      <Clickable className={styles.dismiss} onClick={onDismiss}>
        <Icon name="cross" />
      </Clickable>
    </RequestACallWrapper>
  );
};

export default RequestACallFail;
