import { connect } from 'formik';
// @ts-ignore - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';
import { isFunction, isNull, debounce as lodashDebounce, pick } from 'lodash/fp';
import { useEffect, useMemo, useState } from 'react';

import browserUtils from '../../browser';

type PersistProps = {
  debounce?: number;
  denyList?: string[];
  formik: {};
  isSubmitting: boolean;
  name: string;
  onRehydration?: (...args: unknown[]) => unknown;
};

const Persist = ({ debounce, formik, name, onRehydration }: PersistProps) => {
  const dataToPersist = useMemo(() => ({ ...pick('values', formik) }), [formik]);
  const [isFormikInitialised, setIsFormikInitialised] = useState(false);
  const browserStorage = (browserUtils.getWindow() as any).sessionStorage;

  // @ts-ignore - Automatic, Please fix when editing this file
  const saveForm = lodashDebounce(debounce, () => {
    browserStorage.setItem(name, JSON.stringify(dataToPersist));
  });

  /*
  When we first load or refresh the page, if we have data in session state we want to load it in and overwrite Formik state
  This effect will run on each step of the form (as each step is it's own page).
  We do not save 'touched' and 'errors' Formik state to session storage, as this can lead to a race condition when proceeding to next
  step of the buy form. Race condition is between setting touched state in session storage on the previous form page, and getting that state on the current form page.
  */
  useEffect(() => {
    const getState = browserStorage.getItem(name);
    if (getState && !isNull(getState)) {
      let rehydratedState = JSON.parse(getState);
      if (isFunction(onRehydration)) {
        rehydratedState = onRehydration(rehydratedState);
      }
      // Giving 'values' a default empty object so Immutable.merge doesn't error out trying to merge undefined.
      // @ts-ignore - Automatic, Please fix when editing this file
      const { values: formikValues = {} } = formik;
      const { values: rehydratedValues = {} } = rehydratedState;

      // We need to merge our rehydrated state with what formik will have in its own state,
      // otherwise our formik state does not have any reference to fields we include in the deny list. This prevents
      // all formik state values from being set to nothing if it's forgotten by the deny list (it might still have an
      // initial value). We merge rehydrated onto formik, so the values stored in session override any initial value.
      const mergedState = Immutable.merge(formikValues, rehydratedValues);
      // @ts-ignore - Automatic, Please fix when editing this file
      formik.setFormikState({
        ...formik,
        values: mergedState,
      });
      browserStorage.setItem(name, JSON.stringify({ values: mergedState }));
    } else {
      // @ts-ignore - Automatic, Please fix when editing this file
      const { values: formikValues } = formik;
      browserStorage.setItem(name, JSON.stringify({ values: formikValues }));
    }
    setIsFormikInitialised(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFormikInitialised) {
      // @ts-ignore - Automatic, Please fix when editing this file
      saveForm(debounce);
    }
  }, [dataToPersist, debounce, isFormikInitialised, saveForm]);

  return null;
};

Persist.defaultProps = {
  debounce: 300,
  denyList: null,
  onRehydration: null,
};

// @ts-ignore - Automatic, Please fix when editing this file
export default connect(Persist);

export { Persist as PersistForTest };
