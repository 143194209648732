import { reduce } from 'lodash/fp';

import { CHANGE_EFFECTIVE_DATE, PRICE_LOAD, PRICE_LOAD_FAIL, PRICE_LOAD_SUCCESS } from '../../actions/action-types';
import makeErrorSerialisable from '../../utils/make-error-serialisable';
import uncapFpIterator from '../../../utils/uncap-fp-iterator';

const initialState = {
  data: {},
  loading: false,
  useFutureEffectiveDate: false,
};

export default function priceReducer(state = initialState, action = {}) {
  // @ts-ignore - Automatic, Please fix when editing this file
  switch (action.type) {
    case PRICE_LOAD:
      return {
        ...state,
        loading: true,
      };

    case PRICE_LOAD_SUCCESS: {
      // @ts-ignore - Automatic, Please fix when editing this file
      const { keys } = action;
      // @ts-ignore - Automatic, Please fix when editing this file
      const prices = action.result;

      const mappedKeys = uncapFpIterator(reduce)(
        // @ts-ignore - Automatic, Please fix when editing this file
        (previous, current, index) => ({
          ...previous,
          [current]: prices[index],
        }),
        {},
        keys,
      );

      return {
        ...state,
        data: { ...state.data, ...mappedKeys },
        error: null,
        loading: false,
      };
    }

    case PRICE_LOAD_FAIL:
      return {
        ...state,
        // @ts-ignore - Automatic, Please fix when editing this file
        error: makeErrorSerialisable(action.error),
        loading: false,
      };

    case CHANGE_EFFECTIVE_DATE:
      return {
        ...state,
        loading: false,
        // @ts-ignore - Automatic, Please fix when editing this file
        useFutureEffectiveDate: action.useFutureEffectiveDate,
      };
    default:
      return state;
  }
}
