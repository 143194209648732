import { assign, set } from 'lodash/fp';

import {
  REFERRAL_RESET,
  REFERRAL_SET_CODE,
  REFERRAL_SET_TARGET_SEGMENT,
  REFERRAL_SET_URL,
} from '../../actions/action-types';
import { TARGET_SEGMENT } from '../../cms/constants';

const getDefaultState = () => ({
  code: null,
  targetSegment: TARGET_SEGMENT.LOOKING_FOR_PRIVATE_HEALTH_INSURANCE,
  url: null,
});

// Contains the state for the refer a friend initiative.
export default function referral(state = getDefaultState(), action = {}) {
  // @ts-ignore - Automatic, Please fix when editing this file
  const { type } = action;

  const reducers = {
    [REFERRAL_RESET]: () => assign(state, getDefaultState()),
    // @ts-ignore - Automatic, Please fix when editing this file
    [REFERRAL_SET_CODE]: () => set('code', action.code, state),
    // @ts-ignore - Automatic, Please fix when editing this file
    [REFERRAL_SET_TARGET_SEGMENT]: () => set('targetSegment', action.targetSegment, state),
    // @ts-ignore - Automatic, Please fix when editing this file
    [REFERRAL_SET_URL]: () => set('url', action.url, state),
  };

  // @ts-ignore - Automatic, Please fix when editing this file
  return reducers[type] ? reducers[type]() : state;
}
