import * as globals from '@ahmdigital/constants';
import { flow, intersection, keys, reduce } from 'lodash/fp';
import assert from 'assert';

// Import constants files and attach them to a files object
import * as agr from '../models/agr/constants';
import * as customer from '../models/customer/constants';
import * as price from '../models/price/constants';
import * as ui from './ui';
import * as url from '../models/url/constants';
import uncapFpIterator from '../../utils/uncap-fp-iterator';

const files = { agr, customer, globals, price, ui, url };

// Loop through the imported constant files and check for duplicates,
// as well as extending the constants object to include them.

// @ts-ignore - Automatic, Please fix when editing this file
export const cleanConstants = (constantsImports) =>
  uncapFpIterator(reduce)(
    // @ts-ignore - Automatic, Please fix when editing this file
    (constants, file, filename) => {
      const duplicates = flow(keys, intersection(keys(file)))(constants);
      assert.strictEqual(
        duplicates.length,
        0,
        `Duplicate constant keys found in '${filename}',
      those keys are ambiguous will be overwritten: ${duplicates}`,
      );
      return { ...constants, ...file };
    },
    {},
    constantsImports,
  );

export default cleanConstants(files);
