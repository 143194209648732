import { createSelector } from 'reselect';
import { get, getOr, includes, isNull } from 'lodash/fp';

import constants from '../../../../../../../../../../../ahm-constants';
import selectMembership from '../../../../../../../../../../../selectors/select-membership';

// @ts-ignore - Automatic, Please fix when editing this file
const getCustomerBundle = (customer) => ({
  existingHealthFund: get('switching.name', customer),
  firstName: get('firstName', customer),
  gender: get('gender', customer),
  hasGivenConsent: get('switching.transfer', customer),
  isExistingHealthFundUnknown: get('switching.name', customer) === constants.HEALTH_FUND.OTHER,
  isSwitching: !isNull(getOr(null, 'switching', customer)),
});

export default createSelector(selectMembership, (membership) => {
  const scale = get('scale', membership);
  const client = get('members[0]', membership);
  const spouse = get('members[1]', membership);

  const bundle = {
    coverStartDate: get('starts', membership),
    customers: {
      [constants.RELATIONSHIP.CLIENT]: getCustomerBundle(client),
    },
    scale,
  };

  if (includes(scale, [constants.SCALE.COUPLE, constants.SCALE.FAMILY])) {
    bundle.customers[constants.RELATIONSHIP.SPOUSE] = getCustomerBundle(spouse);
  }

  return bundle;
});
