import {
  compact,
  defaults,
  filter,
  flow,
  get,
  has,
  invert,
  isBoolean,
  isEmpty,
  map,
  mapValues,
  pick,
  set,
  some,
  sortBy,
  split,
  update,
} from 'lodash/fp';
// @ts-ignore - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';
import { WHITELIST } from '@ahmdigital/constants';
import applyWhitelist from '@ahmdigital/logic/lib/utils/apply-whitelist';

import constants from '../../ahm-constants';
import logging from '../../logging';
import uncapFpIterator from '../../../utils/uncap-fp-iterator';

const PRICE_REGEX = /^(\d+).(\d\d)$/;

type Scale = string;

type Date = string;

type PriceOptions = {
  adjustedScale?: boolean;
  date?: Date;
  lhc?: boolean;
};

type Member = {};
type Dependants = Member[];
type Frequency = string;
type Members = Member[];
type Payment = { frequency: string; starts: string; method: string };
export type ProductId = string;
type Price = { amount: { [x: Frequency]: string } };
export type Prices = { [key: string]: Price };

export type AgeBracket = string;
type IncomeTier = string;
type Rebate = boolean;
type Starts = string;
type State = string;

export type PriceData = {
  ageBracket?: AgeBracket;
  dependants?: Dependants;
  incomeTier?: IncomeTier;
  members?: Members;
  payment?: Payment;
  rebate?: Rebate;
  scale?: Scale;
  starts?: Starts;
  state?: State;
};

type RawQuery = {
  ageBracket: AgeBracket;
  date?: Date;
  incomeTier: IncomeTier;
  members?: Members;
  payment: Payment;
  productId: ProductId;
  rebate: Rebate;
  scale?: Scale;
  starts: Starts;
  state: State;
};

// eslint-disable-next-line no-unused-vars
type Api = { get: (arg0: string, arg1: { query: {} }) => Promise<{}> };

const PriceService = {
  /**
   * Filter the members Array to contain members that have all required details filled in for an LHC calculation.
   *
   * @param {Array.<object>} members - A list of members to filter from.
   * @param {object} options - Pricing options ie. LHC enabled.
   * @returns {Array.<object>} A list of members with completed LHC details.
   */
  filterAndSortMembers: function filterAndSortMembers(members: Members, options: PriceOptions = {}) {
    const { lhc = false } = options;

    if (!members || isEmpty(members)) {
      return [];
    }

    return flow(
      filter((member) => has('dob', member) && has('relationship', member)),
      map((member) => {
        const isContinuous = get('continuous', member);

        return Immutable.merge(member, {
          continuous: lhc && isBoolean(isContinuous) ? isContinuous : true,
        });
      }),
      sortBy('relationship'),
    )(members);
  },

  /**
   * Checks for any adult dependants and if they exist, returns the _PLUS_21 variant of the scale selected.
   *
   * @param {string} scale - The initial scale.
   * @param {Array.<object>} dependants - Dependants of the primary member.
   * @returns {string} The _PLUS_21 variant if required, or the input scale.
   */
  getAdjustedScale: function getAdjustedScale(scale: string, dependants: Dependants) {
    const isDependantAdult = (dependant: { relationship: string }) =>
      dependant.relationship === constants.RELATIONSHIP.ADULT_DEPENDANT;

    const adjustedScale =
      dependants && dependants.length && some(isDependantAdult, dependants)
        ? constants.SCALE_ADULT_DEPENDANT_VARIANT[scale]
        : scale;
    return adjustedScale || scale;
  },

  getAmountAsFloat(price: { amount: { [x: number]: string } }, frequency: Frequency) {
    const priceAmountObject = PriceService.getAmountAsObject(price, frequency);
    return parseFloat(`${priceAmountObject.dollars}.${priceAmountObject.cents}`);
  },

  getAmountAsObject(price: Price, frequency: Frequency) {
    const str = price.amount[frequency] || '';

    const matches = split(PRICE_REGEX, str);

    if (!matches) {
      throw new Error(`Invalid price amount for frequency ${frequency}`);
    }

    return {
      cents: matches[2],
      dollars: matches[1],
    };
  },

  getAmountAsString(price: Price, frequency: Frequency) {
    const priceAmountObject = PriceService.getAmountAsObject(price, frequency);
    return `$${priceAmountObject.dollars}.${priceAmountObject.cents}`;
  },

  /**
   * Calculate the complementary product price by subtracting the base price from the packaged product price.
   *
   * @param {object} prices - The loaded prices.
   * @param {string} baseProductId - The id for the base product (unpackaged).
   * @param {string} combinedProductId - The id for the packaged product.
   * @param {object} data - Information about the customer.
   * @param {object} options - Pricing options ie. LHC enabled.
   * @returns {object} The calculated complementary product price.
   */
  getComplementaryPrice: function getComplementaryPrice(
    prices: Prices,
    baseProductId: ProductId,
    combinedProductId: ProductId,
    data: PriceData,
    options: PriceOptions = {},
  ): object {
    const basePrice = PriceService.getPrice(prices, baseProductId, data, options);
    const combinedPrice = PriceService.getPrice(prices, combinedProductId, data, options);
    if (!basePrice || !combinedPrice) {
      // @ts-ignore - Automatic, Please fix when editing this file
      return undefined;
    }

    const amount = flow(
      invert,
      // @ts-ignore - Automatic, Please fix when editing this file
      uncapFpIterator(mapValues)((arg1, frequency) =>
        (Number(combinedPrice.amount[frequency]) - Number(basePrice.amount[frequency])).toFixed(2),
      ),
    )(constants.PAYMENT_FREQUENCY); // @NOTE: This isn’t a full price object; it's the amount wrapped as an Object.

    return {
      amount,
    };
  },

  /**
   * Generates a key to store the price behind based on the options given.
   *
   * @param {string} productId - The relevant product’s id.
   * @param {object} data - Information about the customer.
   * @param {object} options - Pricing options ie. LHC enabled.
   * @returns {string} The generated key matching the given arguments.
   */
  getKey: function getKey(productId: ProductId, data: PriceData, options: PriceOptions = {}) {
    const { dependants, incomeTier, members, payment, rebate, scale, starts, state } = data;
    const { adjustedScale = false, date = null, lhc = false } = options;
    const keyParts = [productId, state, rebate];
    // @ts-ignore - Automatic, Please fix when editing this file
    keyParts.push(adjustedScale ? PriceService.getAdjustedScale(scale, dependants) : scale);

    if (rebate) {
      keyParts.push(incomeTier);
    }

    // @ts-ignore - Automatic, Please fix when editing this file
    const filteredMembers = PriceService.filterAndSortMembers(members, {
      lhc,
    });

    if (!isEmpty(filteredMembers)) {
      const serializedMembers = map(
        (member: { continuous: boolean; dob: string; relationship: string }) =>
          `${member.relationship}:${member.dob}:${member.continuous ? 1 : 0}`,
        filteredMembers,
      ).join('|');

      keyParts.push(serializedMembers);
    }

    if (starts) {
      keyParts.push(starts);
    }

    if (payment) {
      keyParts.push([payment.frequency, payment.starts, payment.method].join(':'));
    }

    if (date) {
      keyParts.push(date);
    }

    const key = keyParts.join('|');
    return key;
  },

  /**
   * Get a price, with caching.
   *
   * @param {object} prices - The loaded prices.
   * @param {string} productId - The product id to get a price for.
   * @param {object} data - Information about the customer.
   * @param {object} options - Pricing options ie. LHC enabled.
   * @returns {object} The price for the given params.
   */
  getPrice: function getPrice(prices: Prices, productId: ProductId, data: PriceData, options: PriceOptions = {}) {
    const key = PriceService.getKey(productId, data, options);
    const price = prices[key];
    return price;
  },

  /**
   * Gets some prices, with caching.
   *
   * @param {object} prices - The loaded prices.
   * @param {Array} productIds - The product ids to get prices for.
   * @param {object} data - Information about the customer.
   * @param {object} options - Pricing options ie. LHC enabled.
   * @returns {Array} The list of the prices for the given params.
   */
  getPrices: function getPrices(prices: Prices, productIds: ProductId[], data: PriceData, options: PriceOptions = {}) {
    return map((productId) => PriceService.getPrice(prices, productId, data, options), productIds);
  },

  /**
   * Load a price from the API.
   *
   * @param {object} api - The api to load prices from.
   * @param {string} productId - The product id to load the price for.
   * @param {object} data - Information about the customer.
   * @param {object} options - Pricing options ie. LHC enabled.
   * @returns {Promise} A promise that will resolve when price loading is complete.
   */
  loadPrice: function loadPrice(
    api: Api,
    productId: ProductId,
    data: PriceData,
    options: PriceOptions = {},
  ): Promise<any> {
    const { ageBracket, dependants, incomeTier, members, payment, rebate, scale, starts, state } = data;
    const { adjustedScale = false, date = null, lhc = false } = options;
    const rawQuery: RawQuery = {
      // @ts-ignore - Automatic, Please fix when editing this file
      ageBracket: rebate ? ageBracket : undefined,
      // @ts-ignore - Automatic, Please fix when editing this file
      incomeTier: rebate ? incomeTier : undefined,
      // @ts-ignore - Automatic, Please fix when editing this file
      payment: payment ? pick(['starts', 'frequency', 'method'], payment) : undefined,
      productId,
      // @ts-ignore - Automatic, Please fix when editing this file
      rebate,
      scale,
      // @ts-ignore - Automatic, Please fix when editing this file
      starts,
      // @ts-ignore - Automatic, Please fix when editing this file
      state,
    };

    if (isEmpty(productId)) {
      logging.getLogger().error('No ProductId provided for loadPrice', rawQuery);
      return Promise.resolve();
    }

    if (adjustedScale) {
      // @ts-ignore - Automatic, Please fix when editing this file
      rawQuery.scale = PriceService.getAdjustedScale(scale, dependants);
    }

    // @ts-ignore - Automatic, Please fix when editing this file
    const filteredMembers = PriceService.filterAndSortMembers(members, {
      lhc,
    });

    if (!isEmpty(filteredMembers)) {
      rawQuery.members = filteredMembers;
    }

    if (date) {
      rawQuery.date = date;
    }

    // Ensure that no unnecessary sensitive data is sent through (particularly on the member objects)
    const query = applyWhitelist(rawQuery, WHITELIST.PRICE_QUERY);
    const key = PriceService.getKey(productId, data, options);

    // @ts-ignore - Automatic, Please fix when editing this file
    if (!PriceService.promises[key]) {
      // @ts-ignore - Automatic, Please fix when editing this file
      PriceService.promises[key] = api
        .get('/products/price', {
          query,
        })
        .then(
          flow(
            get('products[0]'),
            set('key', key),
            update(
              'amount',
              mapValues((value: number) => value.toFixed(2)),
            ),
          ),
        );
    }

    // @ts-ignore - Automatic, Please fix when editing this file
    return PriceService.promises[key];
  },

  /**
   * Call loadPrice for each of the products passed in.
   *
   * @param {object} api - The api to load prices from.
   * @param {Array.<string>} productIds - An array of product ids.
   * @param {object} data - Information about the customer.
   * @param {object} options - Pricing options ie. LHC enabled.
   * @returns {Promise} A promise that will resolve when all prices have loaded.
   */
  loadPrices: function loadPrices(
    api: Api,
    productIds: ProductId[],
    data: PriceData,
    options: PriceOptions = {},
  ): Promise<any> {
    if (some(isEmpty, productIds)) {
      logging.getLogger().warn('Attempted to loadPrices with an invalid productId', {
        productIds,
      });
    }

    const loadPrice =
      // @ts-ignore - Automatic, Please fix when editing this file


        ({ api: apiForPriceLoad, data: dataForPriceLoad, options: optionsForPriceLoad }) =>
        (productId: string) =>
          PriceService.loadPrice(apiForPriceLoad, productId, dataForPriceLoad, optionsForPriceLoad);

    const promise = Promise.all(flow(compact, map(loadPrice({ api, data, options })))(productIds));
    promise.catch(() => PriceService.reset());
    return promise;
  },

  preparePriceData(
    customer: {
      existingCustomerData?: string;
      frequency: Frequency;
      payment: Payment;
      paymentMethod: Payment['method'];
    },
    checkout: { buy: { coverStartDate?: string; paymentStartDate?: string } },
  ) {
    // In the buy form, the email form updates the checkout state. The pricing call will error if coverStartDate is missing and we pass this statement.
    if (!get('buy.coverStartDate', checkout)) {
      return customer;
    }

    return defaults(customer, {
      payment: {
        frequency: customer.frequency,
        method: customer.paymentMethod,
        starts: checkout.buy.paymentStartDate,
      },
      starts: checkout.buy.coverStartDate,
    });
  },

  promises: {},
  reset: function reset() {
    PriceService.promises = {};
  },
};
export default PriceService;
