import { get, includes, values } from 'lodash/fp';

import { DATASET, MIDDAY, MODE, NOW } from '../constants';
import CMS_UPDATE_PREVIEW_OPTIONS from '../action-types';

const initialState = () => ({
  apiToken: null,
  asAtDate: NOW,
  asAtTime: MIDDAY,
  dataset: DATASET.PRODUCTION,
  liveFilteringToken: null,
  mode: MODE.PUBLISHED,
});

export default function cms(state = initialState(), action = {}) {
  const reducers = {
    [CMS_UPDATE_PREVIEW_OPTIONS]: () => {
      const dataset = get('payload.dataset', action);
      const mode = get('payload.mode', action);
      return {
        ...state,
        apiToken: get('payload.apiToken', action) || state.apiToken,
        asAtDate: get('payload.asAtDate', action) || state.asAtDate,
        asAtTime: get('payload.asAtTime', action) || state.asAtTime,
        dataset: includes(dataset, values(DATASET)) ? dataset : state.dataset,
        liveFilteringToken: get('payload.liveFilteringToken', action) || state.liveFilteringToken,
        mode: includes(mode, values(MODE)) ? mode : state.mode,
      };
    },
  };

  // @ts-ignore - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
