import { Button, Fieldset, scrollToFirstError } from '@ahmdigital/ui';
import { Form, useFormikContext } from 'formik';
import { get, isEmpty, isNil } from 'lodash/fp';
import React, { useEffect } from 'react';

import { CaptureFormState, LeadType } from '../../types';
import { LEAD_TYPES } from '../../../../models/lead-capture/constants';
import { PRE_BUY_FORM_NAME } from '../../../../utils/formik/constants';
import FormikField from '../../../formik-field-with-scroll-margin';
import Persist from '../../../../utils/formik/persist';
import styles from '../../styles.module.sass';

type LeadFormProps = {
  analyticsLabel?: string;
  captureFormState?: CaptureFormState;
  // @ts-ignore - Automatic, Please fix when editing this file
  getFields: (component, setFieldTouched, setFieldValue) => JSX.Element;
  leadType: LeadType;
  sharedFieldProps: {
    size: string;
    variant: string;
  };
  submitButtonText: string;
  isLoading?: boolean;
};

// @ts-ignore - Automatic, Please fix when editing this file
const restoreFormikSession = (formik) => formik;

const LeadForm = ({
  analyticsLabel,
  captureFormState,
  getFields,
  leadType,
  sharedFieldProps,
  submitButtonText,
  isLoading,
}: LeadFormProps) => {
  const formik = useFormikContext();
  const { handleSubmit, errors, isSubmitting, setFieldTouched, setFieldValue, touched } = formik;
  const setFormikTouched = get('setFormikTouched', captureFormState);
  const setFormikErrors = get('setFormikErrors', captureFormState);

  // ErrorSummary component requires formik context values. This allows us to pass the errors and touched objects outside the LeadCaptureForm
  useEffect(() => {
    if (setFormikErrors) {
      setFormikErrors(errors);
    }
    if (isSubmitting && !isEmpty(errors)) {
      scrollToFirstError();
    }
  }, [errors, isSubmitting, setFormikErrors]);

  useEffect(() => {
    if (setFormikTouched) {
      setFormikTouched(touched);
    }
  }, [touched, setFormikTouched]);

  return (
    <Form>
      {
        // Persist formik state for abandoned cart pre-buy form so we don't lose the user's data if they navigate back and forth; same as the rest of the buy form
        leadType.component === LEAD_TYPES.ABANDONED_CART_PRE_BUY_FORM.component && (
          // @ts-ignore - Automatic, Please fix when editing this file
          <Persist debounce={200} formik={formik} name={PRE_BUY_FORM_NAME} onRehydration={restoreFormikSession} />
        )
      }
      <Fieldset>
        {getFields(leadType.component, setFieldTouched, setFieldValue)}
        <FormikField
          {...sharedFieldProps}
          fieldLabel="Please contact me about health insurance, discounts, promotions from ahm & partners including health and other insurance products and services."
          name={`marketingOptIn-${leadType.component}`}
          type="checkbox"
          isRequired={leadType.component === LEAD_TYPES.SAVE_AND_RETRIEVE_QUOTE.component}
          variant={leadType.component === LEAD_TYPES.REQUEST_A_CALL.component && styles.checkbox}
        />
        <Button
          analyticsLabel={{ label: isNil(analyticsLabel) ? submitButtonText : analyticsLabel }}
          disabled={isSubmitting}
          onClick={handleSubmit}
          type="submit"
          variant={styles.button}
          isLoading={isLoading}
        >
          {submitButtonText}
        </Button>
      </Fieldset>
    </Form>
  );
};

export default LeadForm;
