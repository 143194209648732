import { get } from 'lodash/fp';
import moment from 'moment';

import config from '../../config';
import constants from '../../ahm-constants';

export const getIsAfterUseFutureEffectiveDate = () =>
  moment().isAfter(moment(config.get('features:premiumChange:futureEffectiveDate')));

const AgrService = {
  /**
   * Get the percentage for a given rebate combination.
   *
   * @param {object} customer - The customer.
   * @param {object=} options - Can set useFutureEffectiveDate.
   * @param {boolean} options.useFutureEffectiveDate - Whether we should use a future effective date, for premium review dual rates time.
   * @returns {string} Rebate percentage.
   */
  // @ts-ignore - Automatic, Please fix when editing this file
  getPercentage: function getPercentage(customer, options: { useFutureEffectiveDate?: boolean } = {}) {
    const { rebate, ageBracket, incomeTier } = customer || {};
    const { useFutureEffectiveDate } = options;

    const rebatePercentages =
      useFutureEffectiveDate || getIsAfterUseFutureEffectiveDate()
        ? constants.REBATE_PERCENTAGES_PREMIUM_CHANGE
        : constants.REBATE_PERCENTAGES;

    if (!rebate) {
      return '0';
    }
    const rebatePercentage = get(incomeTier, get(ageBracket, rebatePercentages));

    return rebatePercentage;
  },

  getRange: function getRange(options: { useFutureEffectiveDate?: boolean } = {}) {
    const { useFutureEffectiveDate } = options;

    const usePremiumChangePercentage = useFutureEffectiveDate || getIsAfterUseFutureEffectiveDate();

    return {
      max: usePremiumChangePercentage
        ? constants.MAX_REBATE_PERCENTAGE_PREMIUM_CHANGE
        : constants.MAX_REBATE_PERCENTAGE,
      min: usePremiumChangePercentage
        ? constants.MIN_REBATE_PERCENTAGE_PREMIUM_CHANGE
        : constants.MIN_REBATE_PERCENTAGE,
    };
  },
};

export default AgrService;
