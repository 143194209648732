import abcLogoImage from './images/abc-logo.svg';
import config from '../../config';
import constants from '../../ahm-constants';

const messageId = config.get('liveChat:appleBusinessChat:messageId');

// @ts-ignore - Automatic, Please fix when editing this file
const getAppleBusinessChatData = ({ isAbcSupportedAndAvailable }) => ({
  image: { alt: 'Apple business chat logo', src: abcLogoImage.src },
  isSupported: isAbcSupportedAndAvailable,
  linkTo: `${constants.URLS.THIRD_PARTY.APPLE_BUSINESS_CHAT}${messageId}`,
});

export default getAppleBusinessChatData;
