// @ts-ignore - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';

import { ACCORDION_UPDATE_SELECTION } from '../../actions/action-types';

export default function accordionReducer(state = {}, action = {}) {
  // @ts-ignore - Automatic, Please fix when editing this file
  const { payload } = action;
  const reducers = {
    [ACCORDION_UPDATE_SELECTION]: () => Immutable.setIn(state, ['openIndices', payload.id], payload.openIndices),
  };

  // @ts-ignore - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
