import { get, getOr, includes } from 'lodash/fp';
import { LiveChat } from '@ahmdigital/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import classNames from 'classnames';
// @ts-ignore - Automatic, Please fix when editing this file
import liveChatActions from '@ahmdigital/livechat/lib/actions';
import React from 'react';

import device from '../../utils/device';
import getAdjustmentClassname from './utils/get-adjustment-classname';
import liveChatConfig from '../../utils/livechat';
import localConstants from '../../ahm-constants';
import messageUsRegularImage from './images/message-us-regular.svg';
import RequestACall from '../../components/request-a-call';
import selectLastViewedProduct from '../../selectors/select-last-viewed-product';
import styles from './styles.module.sass';
import useIntersectionObserver from '../../pages/choosable/pages/picker/hooks/use-intersection-observer';
import useRequestACall from './utils/use-request-a-call';

type FooterLinksProps = {
  footerRef: (...args: unknown[]) =>
    | unknown
    | {
        current?: {};
      };
};

const FooterLinks = ({ footerRef }: FooterLinksProps) => {
  const adjustFooterLinks: boolean = useSelector((state) => get('footer.adjustFooterLinks')(state));
  const liveChatStatus = useSelector(get('livechat.status'));
  const lastViewedProduct = useSelector((state) => selectLastViewedProduct(state));
  const dispatch = useDispatch();
  const { entry: observer } = useIntersectionObserver(footerRef);
  const isIntersectingWithFooter = getOr(false, 'isIntersecting', observer);
  const pathname = get('asPath', useRouter());
  // Any page specific styling for the FooterLinks is only applied when the FooterLinks
  // component is not intersecting with the page footer
  const isFooterAdjusted = adjustFooterLinks && !isIntersectingWithFooter;
  const footerClassName = isFooterAdjusted && getAdjustmentClassname(pathname);

  const showLiveChat = !device.isMobile() && !includes(pathname, localConstants.LIVEPERSON.LIVECHAT_URL_BLACKLIST);
  const showRequestACall = useRequestACall(pathname, lastViewedProduct);

  return (
    <div className={classNames(styles.footerLinks, footerClassName && styles[footerClassName as string])}>
      <div className={styles.autoLeft}>{showRequestACall && <RequestACall />}</div>
      {showLiveChat && (
        <div className={styles.liveChat}>
          <LiveChat
            image={{
              alt: 'Speech bubble saying “Message us”',
              src: messageUsRegularImage.src,
            }}
            launchChatWindow={() => liveChatActions.launch(liveChatConfig)(dispatch)}
            status={liveChatStatus}
          />
        </div>
      )}
    </div>
  );
};

export default FooterLinks;
