import { every } from 'lodash/fp';
// @ts-ignore - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';

import ProductService from '../../../models/product';

/**
 * Get the product name and id, including merging them if appropriate.
 *
 * @param {object} state - State containing potentially a hospital and an extras product.
 * @param {object} product - The base product (last accessed product page).
 * @param {string} complementaryProductType - Hospital or Extras constant, the opposite of the product’s type.
 *
 * @returns {object} Product constructed of the input products, including merged name and id if hospital + extras in state.
 */
// @ts-ignore - Automatic, Please fix when editing this file
const getConstructedProduct = (state, product, complementaryProductType) => {
  if (every('id', [state.hospital, state.extras])) {
    return ProductService.getCombinedCartObject({ extras: state.extras, hospital: state.hospital });
  }

  return ProductService.getCartObject(product || state[complementaryProductType]);
};

/**
 * Set the complementary product (hospital/extras).
 *
 * @param {object} opts - Options.
 * @param {object|null} opts.product - The incoming product.
 * @param {string} opts.productType - Hospital or extras.
 * @param {object} opts.state - Current redux state.
 *
 * @returns {object} New redux state.
 */
const setComplementary =
  // @ts-ignore - Automatic, Please fix when editing this file


    ({ product, productType, state }) =>
    () => {
      const stateWithCartObject = Immutable.set(state, productType, ProductService.getCartObject(product));
      const constructedProduct = getConstructedProduct(
        stateWithCartObject,
        product,
        ProductService.getComplementaryType({ type: productType }),
      );
      return Immutable.set(stateWithCartObject, 'product', constructedProduct);
    };

export default setComplementary;
