import { Clickable } from '@ahmdigital/ui';
import { findIndex, flow, isNil, map, omit, set } from 'lodash/fp';
import getOpeningHours from '@ahmdigital/logic/lib/utils/get-opening-hours';
import React, { Fragment } from 'react';

import { contactUsPageLink, FooterStaticProps, NavLink } from '../../types';
import { INCOME_LIFE_CONTACT_LINKS } from '../../constants';
import { LIFE_INSURANCE } from '../../../../models/life-insurance/constants';
import constants from '../../../../ahm-constants';
import getStaticProps from '../get-static-props';

const getIncomeProtectionInsuranceProps = (): FooterStaticProps => {
  const props = getStaticProps();
  const openingHoursKey = constants.AHM_SERVICE_NAME.LIFE_INSURANCE_ZURICH;

  const formsAndGuidesIndex = findIndex((link: NavLink) => link.name === 'Forms and guides')(props.navLinks);

  const cloneProps = flow(
    omit('appCalloutIcons'),
    // @ts-ignore - Automatic, Please fix when editing this file
    !isNil(formsAndGuidesIndex)
      ? set(`navLinks[${formsAndGuidesIndex}].linkTo`, constants.URLS.SALES.INCOME_INSURANCE_FORMS_AND_GUIDES)
      : null,
  )(props) as FooterStaticProps;

  const contacts = [
    {
      contactHours: getOpeningHours(openingHoursKey),
      contactNumber: LIFE_INSURANCE.NEOS.CONTACT.PHONE.SALES.PRETTY,
      contactNumberPrefix: 'For help joining, call us on',
    },
    {
      contactNumberPrefix: (
        <>
          {map((contactUsPage: contactUsPageLink) => (
            <Fragment key={contactUsPage.link}>
              {contactUsPage.description} <Clickable to={contactUsPage.link}>contact us</Clickable>
              <br />
            </Fragment>
          ))(INCOME_LIFE_CONTACT_LINKS)}
        </>
      ),
    },
  ];

  return {
    ...cloneProps,
    contacts,
  };
};

export default getIncomeProtectionInsuranceProps;
