import { useFeatureValue } from '@growthbook/growthbook-react';

import {
  GROWTHBOOK_PRODUCT_PAGE_OPTIMISATION_EXPERIMENT,
  GROWTHBOOK_PRODUCT_PAGE_OPTIMISATION_VARIANT,
} from '../../../../ahm-constants/ui';

const useProductPageOptimisation = () => {
  const upliftFeatureValue = useFeatureValue(
    GROWTHBOOK_PRODUCT_PAGE_OPTIMISATION_EXPERIMENT,
    GROWTHBOOK_PRODUCT_PAGE_OPTIMISATION_VARIANT.ORIGINAL,
  );

  return upliftFeatureValue === GROWTHBOOK_PRODUCT_PAGE_OPTIMISATION_VARIANT.NEW;
};

export default useProductPageOptimisation;
