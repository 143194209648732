import { get } from 'lodash/fp';
// @ts-ignore - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';
import { REHYDRATE } from 'redux-persist';

import { BUY_SUBMIT, PAYMENT_SUBMIT_SUCCESS } from '../../actions/action-types';

const initialState = {};
/**
 * Membership represents the customer’s membership once they have completed the checkout process.
 *
 * @param {object} oldState - The membership branch of state in its current form.
 * @param {object} action - Redux action.
 * @returns {object} A new object that represents the membership branch of state.
 */

export default function membershipReducer(oldState = initialState, action = {}) {
  const state = Immutable.from(oldState);
  const reducers = {
    [BUY_SUBMIT]: () =>
      // Empty when the customer submits the buy form.
      // This is helpful when they are putting through a second join in the same session.
      initialState,
    [PAYMENT_SUBMIT_SUCCESS]: () =>
      // Store a copy of the data that we collected during the checkout process,
      // also store the cart and offers so that details can display on the success page.
      Immutable.asMutable(
        // @ts-ignore - Automatic, Please fix when editing this file
        Immutable.merge(action.result, {
          // @ts-ignore - Automatic, Please fix when editing this file
          cart: action.cart,
          // @ts-ignore - Automatic, Please fix when editing this file
          offers: action.offers,
        }),
      ),
    [REHYDRATE]: () => {
      // @ts-ignore - Automatic, Please fix when editing this file
      if (action.key === 'membership' && get('payload', action)) {
        // @ts-ignore - Automatic, Please fix when editing this file
        return Immutable.asMutable(Immutable.merge(state, action.payload));
      }

      return Immutable.asMutable(state);
    },
  };
  // @ts-ignore - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : Immutable.asMutable(state);
}
