import { set } from 'lodash/fp';

import getIsServer from '../get-is-server';

// @ts-ignore - Automatic, Please fix when editing this file
const getApiClientConfig = (config) => {
  const apiConfig = config.get('api');
  const isServer = getIsServer();
  return isServer ? set('userAgent', 'Sales SSR', apiConfig) : apiConfig;
};

export default getApiClientConfig;
