import { has, mergeAll, omit } from 'lodash/fp';
// @ts-ignore - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';

import {
  OFFERS_ADD_BY_PROMO_CODE,
  OFFERS_REMOVE,
  OFFERS_UPDATE,
  PRODUCT_CHANGE,
  SCALE_CHANGE,
} from '../../actions/action-types';
/*
 * Data structure is as follows:
 *
 * {
 *   offers: {
 *     lumo: {
 *       code: 'lumofriends',
 *       meta: {},
 *     },
 *     ... more items
 *   }
 * }
 */

const getDefaultState = () => ({
  offers: {},
});

export default function promo(oldState = getDefaultState(), action = {}) {
  const state = Immutable.from(oldState);
  // @ts-ignore - Automatic, Please fix when editing this file
  const { type, payload = {} } = action;
  // @ts-ignore - Automatic, Please fix when editing this file
  const setOfferData = (id, data) => Immutable.setIn(state, ['offers', id], data);

  const reducers = {
    [OFFERS_ADD_BY_PROMO_CODE]: () => {
      // @ts-ignore - Automatic, Please fix when editing this file
      const { code, id } = action;
      return setOfferData(id, mergeAll([payload, { code }]));
    },
    // @ts-ignore - Automatic, Please fix when editing this file
    [OFFERS_REMOVE]: () => Immutable.set(state, 'offers', omit(action.offerId, state.offers)),
    [OFFERS_UPDATE]: () => {
      // @ts-ignore - Automatic, Please fix when editing this file
      if (has(action.offerId, state.offers)) {
        // @ts-ignore - Automatic, Please fix when editing this file
        return setOfferData(action.offerId, Immutable.merge(state.offers[action.offerId], payload, { deep: true }));
      }
      return state;
    },
    [PRODUCT_CHANGE]: getDefaultState,
    [SCALE_CHANGE]: getDefaultState,
  };

  // @ts-ignore - Automatic, Please fix when editing this file
  return reducers[type] ? reducers[type]() : state;
}
