/**
 * Analytics Redux middleware.
 * Used to monitor various state tree changes to record analytics.
 * As an example we track all modals being opened / closed.
 */

import { includes, replace } from 'lodash/fp';
import { REHYDRATE } from 'redux-persist';

import {
  BUY_PROGRESS_LOADED,
  CLOSE_MODAL,
  CONTINUOUS_COVER_CHANGE,
  DATE_OF_BIRTH_CHANGE,
  FREQUENCY_CHANGE,
  INCOME_CHANGE,
  INCOME_TIER_CHANGE,
  INCOME_TYPE_CHANGE,
  OFFERS_ADD_BY_PROMO_CODE,
  OFFERS_REMOVE,
  OPEN_MODAL,
  PAYMENT_METHOD_CHANGE,
  PRODUCT_CHANGE,
  REBATE_CHANGE,
  SCALE_CHANGE,
  STATE_CHANGE,
  SWITCHING_CHANGE,
} from '../actions/action-types';
import analytics from '../analytics';

/**
 * List of actions that should fire profile updates to analytics.
 */
const PROFILE_ACTIONS = {
  BUY_PROGRESS_LOADED,
  CONTINUOUS_COVER_CHANGE,
  DATE_OF_BIRTH_CHANGE,
  FREQUENCY_CHANGE,
  INCOME_CHANGE,
  INCOME_TIER_CHANGE,
  INCOME_TYPE_CHANGE,
  OFFERS_ADD_BY_PROMO_CODE,
  OFFERS_REMOVE,
  PAYMENT_METHOD_CHANGE,
  PRODUCT_CHANGE,
  REBATE_CHANGE,
  SCALE_CHANGE,
  STATE_CHANGE,
  SWITCHING_CHANGE,
};

/**
 * Export the middleware method.
 */
const analyticsMiddleware = (/* {dispatch, getState} */) => {
  // @ts-ignore - Automatic, Please fix when editing this file
  let rehydrateTimer = null;
  // @ts-ignore - Automatic, Please fix when editing this file
  let assignTimer = null;

  // @ts-ignore - Automatic, Please fix when editing this file
  return (next) => (action) => {
    // Listen to certain actions to fire analytics on
    switch (action.type) {
      case REHYDRATE:
        // @ts-ignore - Automatic, Please fix when editing this file
        clearTimeout(rehydrateTimer);
        rehydrateTimer = setTimeout(() => {
          analytics.publish('profile:rehydrate');
        }, 500);
        break;
      case OPEN_MODAL:
      case CLOSE_MODAL:
        // Publish analytics for when modals are opened / closed
        analytics.publish('action', {
          action: action.type === OPEN_MODAL ? 'opened' : 'closed',
          component: 'Modal',
          element: replace('-', ' ', action.id) || 'unknown',
        });
        break;
      default:
        break;
    }

    // Update profile attributes if in the list of profile properties to listen for
    if (includes(action.type, PROFILE_ACTIONS)) {
      // @ts-ignore - Automatic, Please fix when editing this file
      clearTimeout(assignTimer);
      assignTimer = setTimeout(() => {
        analytics.publish('profile:assign');
      }, 250);
    }

    return next(action);
  };
};

export default analyticsMiddleware;
