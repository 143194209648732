import { isNull } from 'lodash/fp';
import getMelbourneTimezoneMoment from '@ahmdigital/logic/lib/utils/get-melbourne-timezone-moment';
import moment from 'moment';

import * as dateValidation from '../../../../utils/date-validation';
import constants from '../../../../ahm-constants';

// @ts-ignore - Automatic, Please fix when editing this file
const getValidPaymentStartDate = ({ coverStartDate, paymentStartDate }) => {
  const isPaymentStartDateValid = isNull(dateValidation.paymentStartDate()(paymentStartDate, { coverStartDate }));
  if (isPaymentStartDateValid) {
    return paymentStartDate;
  }

  const twoDaysFromNowMoment = getMelbourneTimezoneMoment().add(2, 'days');
  const coverStartMoment = moment(coverStartDate, constants.DATE_FORMAT.STANDARD, true);
  return moment.max(twoDaysFromNowMoment, coverStartMoment).format(constants.DATE_FORMAT.STANDARD);
};

export default getValidPaymentStartDate;
