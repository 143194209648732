import { get, isEmpty } from 'lodash/fp';

import logger from '../../../../logging';

// @ts-ignore - Automatic, Please fix when editing this file
const isValidPerkNavItem = (perk) => {
  const perkItem = get('item', perk);
  if (!perkItem) {
    logger.getLogger().warn('Missing perk in table of contents item used in nav header.', { perk });
    return false;
  }

  const hasNavigablePath =
    (!isEmpty(get('descriptionTeaser', perkItem)) && !isEmpty(get('identifier.current', perkItem))) ||
    !isEmpty(get('urlObject.url', perkItem));

  if (get('__typename', perkItem) === 'MemberPerk' && !isEmpty(get('name', perk)) && hasNavigablePath) {
    return true;
  }

  logger.getLogger().warn('Cannot build nav item from table of contents item used in nav header.', { perk });
  return false;
};

export default isValidPerkNavItem;
