import { clamp, flow, inRange, map, mean } from 'lodash/fp';

// @ts-ignore - Automatic, Please fix when editing this file
const getDiscountFromAge = (age) => {
  if (!inRange(18, 30, age)) {
    return 0;
  }
  return 60 - clamp(50, 60, 2 * age);
};

// @ts-ignore - Automatic, Please fix when editing this file
const getYouthDiscountPercentageFromAges = (ages) => flow(map(getDiscountFromAge), mean)(ages);

export default getYouthDiscountPercentageFromAges;
