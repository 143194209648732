import { bindActionCreators } from 'redux';
import { Clickable, H1, P } from '@ahmdigital/ui';
import { connect } from 'react-redux';
import { LEAD_SOURCE_TYPES } from '@ahmdigital/constants';
import { tagmanager as TagManager } from '@ahmdigital/analytics';
import Icon from '@ahmdigital/icons-web';
import React from 'react';

import { LEAD_MESSAGES, LEAD_TYPES } from '../../models/lead-capture/constants';
import LeadCaptureForm from '../lead-capture-form';
import RequestACallActions from '../../actions/request-a-call';
import RequestACallFail from './fail';
import RequestACallMinimized from './minimized';
import RequestACallSuccess from './success';
import RequestACallWrapper from './wrapper';
import styles from './styles.module.sass';
import type { RequestACallActions as RequestACallActionsType, RequestACallState } from './types';

type RequestACallProps = {
  actions: RequestACallActionsType;
  requestACall: RequestACallState;
};

const RequestACall = ({ actions, requestACall }: RequestACallProps) => {
  const { captured, dismissed, expanded, failed, rule, submitted, visible } = requestACall;
  const { message, submitButtonText, title } = LEAD_MESSAGES.REQUEST_A_CALL;

  // @ts-ignore - Automatic, Please fix when editing this file
  const onRequestACallSubmit = (values) => {
    TagManager.submit('request_a_callback', {
      // eslint-disable-next-line camelcase
      is_member: values.isMember ? 'yes' : 'no',
    });
    actions.requestACall.capture();
  };

  if (captured || dismissed || !rule || !visible) {
    return null;
  }

  if (!expanded) {
    return <RequestACallMinimized actions={actions} />;
  }

  if (failed) {
    return <RequestACallFail actions={actions} />;
  }

  if (submitted) {
    return <RequestACallSuccess actions={actions} />;
  }

  return (
    <RequestACallWrapper>
      <Clickable className={styles.dismiss} aria-label="close" onClick={actions.requestACall.collapse}>
        <Icon name="cross" />
      </Clickable>
      <H1 looksLike="h4">{title}</H1>
      <P>{message}</P>
      <LeadCaptureForm
        leadType={LEAD_TYPES.REQUEST_A_CALL}
        onSubmit={onRequestACallSubmit}
        sourceType={LEAD_SOURCE_TYPES.REQUEST_A_CALL}
        submitButtonText={submitButtonText}
      />
    </RequestACallWrapper>
  );
};

// @ts-ignore - Automatic, Please fix when editing this file
const mapStateToProps = (state) => ({
  requestACall: state.requestACall,
});

// @ts-ignore - Automatic, Please fix when editing this file
const mapDispatchToProps = (dispatch) => ({
  actions: {
    requestACall: bindActionCreators(RequestACallActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestACall);
